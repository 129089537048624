.btn:focus-visible {
  outline: 1px solid;
  border-radius: 2px;
  outline-color: var(--color-accent-active);
}

.btn:hover:not([disabled]) {
  cursor: pointer;
}

.btnText {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary-link);
  text-align: left;
}
