.block {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.promo1_short_wide {
  font-family: var(--typography-v3-promo1-short-wide-font-family);
  font-size: var(--typography-v3-promo1-short-wide-font-size);
  line-height: var(--typography-v3-promo1-short-wide-line-height);
  font-weight: var(--typography-v3-promo1-short-wide-font-weight);
  text-transform: var(--typography-v3-promo1-short-wide-text-transform);
}

.promo1_long_wide {
  font-family: var(--typography-v3-promo1-long-wide-font-family);
  font-size: var(--typography-v3-promo1-long-wide-font-size);
  line-height: var(--typography-v3-promo1-long-wide-line-height);
  font-weight: var(--typography-v3-promo1-long-wide-font-weight);
}

.promo2_short_wide {
  font-family: var(--typography-v3-promo2-short-wide-font-family);
  font-size: var(--typography-v3-promo2-short-wide-font-size);
  line-height: var(--typography-v3-promo2-short-wide-line-height);
  font-weight: var(--typography-v3-promo2-short-wide-font-weight);
  text-transform: var(--typography-v3-promo2-short-wide-text-transform);
}

.promo2_long_wide {
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
}

.promo_56_56_wide {
  font-family: var(--typography-v3-promo-56-56-wide-font-family);
  font-size: var(--typography-v3-promo-56-56-wide-font-size);
  line-height: var(--typography-v3-promo-56-56-wide-line-height);
  font-weight: var(--typography-v3-promo-56-56-wide-font-weight);
}

.promo_56_56_upp_wide {
  font-family: var(--typography-v3-promo-56-56-upp-wide-font-family);
  font-size: var(--typography-v3-promo-56-56-upp-wide-font-size);
  line-height: var(--typography-v3-promo-56-56-upp-wide-line-height);
  font-weight: var(--typography-v3-promo-56-56-upp-wide-font-weight);
  text-transform: var(--typography-v3-promo-56-56-upp-wide-text-transform);
}

.promo_36_36_wide {
  font-family: var(--typography-v3-promo-36-36-wide-font-family);
  font-size: var(--typography-v3-promo-36-36-wide-font-size);
  line-height: var(--typography-v3-promo-36-36-wide-line-height);
  font-weight: var(--typography-v3-promo-36-36-wide-font-weight);
}

.promo_36_36_upp_wide {
  font-family: var(--typography-v3-promo-36-36-upp-wide-font-family);
  font-size: var(--typography-v3-promo-36-36-upp-wide-font-size);
  line-height: var(--typography-v3-promo-36-36-upp-wide-line-height);
  font-weight: var(--typography-v3-promo-36-36-upp-wide-font-weight);
  text-transform: var(--typography-v3-promo-36-36-upp-wide-text-transform);
}

.h1_wide {
  font-family: var(--typography-v3-h1-wide-font-family);
  font-size: var(--typography-v3-h1-wide-font-size);
  line-height: var(--typography-v3-h1-wide-line-height);
  font-weight: var(--typography-v3-h1-wide-font-weight);
}

.h1_comp {
  font-family: var(--typography-v3-h1-comp-font-family);
  font-size: var(--typography-v3-h1-comp-font-size);
  line-height: var(--typography-v3-h1-comp-line-height);
  font-weight: var(--typography-v3-h1-comp-font-weight);
}

.h2_wide {
  font-family: var(--typography-v3-h2-wide-font-family);
  font-size: var(--typography-v3-h2-wide-font-size);
  line-height: var(--typography-v3-h2-wide-line-height);
  font-weight: var(--typography-v3-h2-wide-font-weight);
}
.h2_comp {
  font-family: var(--typography-v3-h2-comp-font-family);
  font-size: var(--typography-v3-h2-comp-font-size);
  line-height: var(--typography-v3-h2-comp-line-height);
  font-weight: var(--typography-v3-h2-comp-font-weight);
}
.h3_wide {
  font-family: var(--typography-v3-h3-wide-font-family);
  font-size: var(--typography-v3-h3-wide-font-size);
  line-height: var(--typography-v3-h3-wide-line-height);
  font-weight: var(--typography-v3-h3-wide-font-weight);
}
.h3_comp {
  font-family: var(--typography-v3-h3-comp-font-family);
  font-size: var(--typography-v3-h3-comp-font-size);
  line-height: var(--typography-v3-h3-comp-line-height);
  font-weight: var(--typography-v3-h3-comp-font-weight);
}
.h3_text {
  font-family: var(--typography-v3-h3-text-font-family);
  font-size: var(--typography-v3-h3-text-font-size);
  line-height: var(--typography-v3-h3-text-line-height);
  font-weight: var(--typography-v3-h3-text-font-weight);
}

.h4_wide {
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}
.h4_comp {
  font-family: var(--typography-v3-h4-comp-font-family);
  font-size: var(--typography-v3-h4-comp-font-size);
  line-height: var(--typography-v3-h4-comp-line-height);
  font-weight: var(--typography-v3-h4-comp-font-weight);
}
.h4_text {
  font-family: var(--typography-v3-h4-text-font-family);
  font-size: var(--typography-v3-h4-text-font-size);
  line-height: var(--typography-v3-h4-text-line-height);
  font-weight: var(--typography-v3-h4-text-font-weight);
}

.p1_regular_comp {
  font-family: var(--typography-v3-p1-regular-comp-font-family);
  font-size: var(--typography-v3-p1-regular-comp-font-size);
  line-height: var(--typography-v3-p1-regular-comp-line-height);
  font-weight: var(--typography-v3-p1-regular-comp-font-weight);
}
.p1_regular_text {
  font-family: var(--typography-v3-p1-regular-text-font-family);
  font-size: var(--typography-v3-p1-regular-text-font-size);
  line-height: var(--typography-v3-p1-regular-text-line-height);
  font-weight: var(--typography-v3-p1-regular-text-font-weight);
}

.p2_regular_comp {
  font-family: var(--typography-v3-p2-regular-comp-font-family);
  font-size: var(--typography-v3-p2-regular-comp-font-size);
  line-height: var(--typography-v3-p2-regular-comp-line-height);
  font-weight: var(--typography-v3-p2-regular-comp-font-weight);
}
.p2_regular_text {
  font-family: var(--typography-v3-p2-regular-text-font-family);
  font-size: var(--typography-v3-p2-regular-text-font-size);
  line-height: var(--typography-v3-p2-regular-text-line-height);
  font-weight: var(--typography-v3-p2-regular-text-font-weight);
}

.p3_bold_comp {
  font-family: var(--typography-v3-p3-bold-comp-font-family);
  font-size: var(--typography-v3-p3-bold-comp-font-size);
  line-height: var(--typography-v3-p3-bold-comp-line-height);
  font-weight: var(--typography-v3-p3-bold-comp-font-weight);
}
.p3_medium_comp {
  font-family: var(--typography-v3-p3-medium-comp-font-family);
  font-size: var(--typography-v3-p3-medium-comp-font-size);
  line-height: var(--typography-v3-p3-medium-comp-line-height);
  font-weight: var(--typography-v3-p3-medium-comp-font-weight);
}
.p3_regular_comp {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}
.p3_medium_text {
  font-family: var(--typography-v3-p3-medium-text-font-family);
  font-size: var(--typography-v3-p3-medium-text-font-size);
  line-height: var(--typography-v3-p3-medium-text-line-height);
  font-weight: var(--typography-v3-p3-medium-text-font-weight);
}
.p3_regular_text {
  font-family: var(--typography-v3-p3-regular-text-font-family);
  font-size: var(--typography-v3-p3-regular-text-font-size);
  line-height: var(--typography-v3-p3-regular-text-line-height);
  font-weight: var(--typography-v3-p3-regular-text-font-weight);
}

.p4_bold_comp {
  font-family: var(--typography-v3-p4-bold-comp-font-family);
  font-size: var(--typography-v3-p4-bold-comp-font-size);
  line-height: var(--typography-v3-p4-bold-comp-line-height);
  font-weight: var(--typography-v3-p4-bold-comp-font-weight);
}
.p4_medium_comp {
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
}
.p4_medium_upp_comp {
  font-family: var(--typography-v3-p4-medium-upp-comp-font-family);
  font-size: var(--typography-v3-p4-medium-upp-comp-font-size);
  line-height: var(--typography-v3-p4-medium-upp-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-upp-comp-font-weight);
  text-transform: var(--typography-v3-p4-medium-upp-comp-text-transform);
}
.p4_medium_upp_text {
  font-family: var(--typography-v3-p4-medium-upp-text-font-family);
  font-size: var(--typography-v3-p4-medium-upp-text-font-size);
  line-height: var(--typography-v3-p4-medium-upp-text-line-height);
  font-weight: var(--typography-v3-p4-medium-upp-text-font-weight);
  text-transform: var(--typography-v3-p4-medium-upp-text-text-transform);
}
.p4_regular_comp {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}
.p4_regular_text {
  font-family: var(--typography-v3-p4-regular-text-font-family);
  font-size: var(--typography-v3-p4-regular-text-font-size);
  line-height: var(--typography-v3-p4-regular-text-line-height);
  font-weight: var(--typography-v3-p4-regular-text-font-weight);
}
.p4_bold_upp_wide {
  font-family: var(--typography-v3-p4-bold-upp-wide-font-family);
  font-size: var(--typography-v3-p4-bold-upp-wide-font-size);
  line-height: var(--typography-v3-p4-bold-upp-wide-line-height);
  font-weight: var(--typography-v3-p4-bold-upp-wide-font-weight);
  text-transform: var(--typography-v3-p4-bold-upp-wide-text-transform);
  letter-spacing: var(--typography-v3-p4-bold-upp-wide-letter-spacing);
}

.c1_bold_comp {
  font-family: var(--typography-v3-c1-bold-comp-font-family);
  font-size: var(--typography-v3-c1-bold-comp-font-size);
  line-height: var(--typography-v3-c1-bold-comp-line-height);
  font-weight: var(--typography-v3-c1-bold-comp-font-weight);
}
.c1_bold_upp_wide {
  font-family: var(--typography-v3-c1-bold-upp-wide-font-family);
  font-size: var(--typography-v3-c1-bold-upp-wide-font-size);
  line-height: var(--typography-v3-c1-bold-upp-wide-line-height);
  font-weight: var(--typography-v3-c1-bold-upp-wide-font-weight);
  text-transform: var(--typography-v3-c1-bold-upp-wide-text-transform);
  letter-spacing: var(--typography-v3-c1-bold-upp-wide-letter-spacing);
}
.c1_medium_comp {
  font-family: var(--typography-v3-c1-medium-comp-font-family);
  font-size: var(--typography-v3-c1-medium-comp-font-size);
  line-height: var(--typography-v3-c1-medium-comp-line-height);
  font-weight: var(--typography-v3-c1-medium-comp-font-weight);
}
.c1_medium_upp_comp {
  font-family: var(--typography-v3-c1-medium-upp-comp-font-family);
  font-size: var(--typography-v3-c1-medium-upp-comp-font-size);
  line-height: var(--typography-v3-c1-medium-upp-comp-line-height);
  font-weight: var(--typography-v3-c1-medium-upp-comp-font-weight);
  text-transform: var(--typography-v3-c1-medium-upp-comp-text-transform);
}
.c1_regular_comp {
  font-family: var(--typography-v3-c1-regular-comp-font-family);
  font-size: var(--typography-v3-c1-regular-comp-font-size);
  line-height: var(--typography-v3-c1-regular-comp-line-height);
  font-weight: var(--typography-v3-c1-regular-comp-font-weight);
}

.c2_bold_upp_wide {
  font-family: var(--typography-v3-c2-bold-upp-wide-font-family);
  font-size: var(--typography-v3-c2-bold-upp-wide-font-size);
  line-height: var(--typography-v3-c2-bold-upp-wide-line-height);
  font-weight: var(--typography-v3-c2-bold-upp-wide-font-weight);
  text-transform: var(--typography-v3-c2-bold-upp-wide-text-transform);
  letter-spacing: var(--typography-v3-c2-bold-upp-wide-letter-spacing);
}
