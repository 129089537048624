.blockFooterLinks {
  display: grid;
  grid-template-columns: 57% auto;
  padding: 24px 0px;
}

.appLinks {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.appLink {
  display: flex;
  text-decoration: none;
  gap: 12px;
  align-items: center;
}

.appLinkContent {
  display: flex;
  flex-direction: column;
}

.appLinkContentTitle {
  text-align: start;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}

.appLinkContentText {
  text-align: start;
  color: var(--color-text-secondary);
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}

.links {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 24px;
}

.link {
  text-decoration: none;
  color: var(--color-text-secondary-link);
  transition: color 0.2s;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}

.link:hover {
  color: var(--color-brand);
}

@media only screen and (max-width: 1439.95px) {
  .links {
    column-gap: 12px;
  }
}

@media only screen and (max-width: 1279.95px) {
  .appLinks {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767.95px) {
  .blockFooterLinks {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 84px;
    padding: 20px 0px;
  }

  .links {
    flex-direction: column;
    row-gap: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1439.95px) {
  .appLinkContentTitle,
  .appLinkContentText,
  .link {
    font-family: var(--typography-v3-p4-regular-comp-font-family);
    font-size: var(--typography-v3-p4-regular-comp-font-size);
    line-height: var(--typography-v3-p4-regular-comp-line-height);
    font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  }
}
