.tabs {
  display: flex;
  margin-top: 40px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}
