.header {
  display: grid;
  grid-template-columns: 1fr 145px;
  gap: 16px;
  align-items: start;
}
.headerInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.headerInfoDesc {
  text-align: left;
  color: var(--color-text-secondary);
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}

.buttonText {
  display: flex;
  align-items: center;
  gap: 4px;
  text-wrap: nowrap;
  padding-right: 4px;
}
.table {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.tablePagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
