.header {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.headerTitle {
  text-align: left;
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  color: var(--color-text-primary);
}

.discounts {
  max-width: 296px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.discountQuantityLabel {
  text-align: left;
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
  color: var(--color-text-primary);
}
.discountQuantityDesc {
  text-align: left;
  margin-top: 24px;
  font-family: var(--typography-v3-c1-regular-comp-font-family);
  font-size: var(--typography-v3-c1-regular-comp-font-size);
  line-height: var(--typography-v3-c1-regular-comp-line-height);
  font-weight: var(--typography-v3-c1-regular-comp-font-weight);
  color: var(--color-text-secondary);
}
