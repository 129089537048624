.swiper {
  width: 100%;
  position: relative;
}

.swiper::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 48px;
  background: linear-gradient(90deg, #010101 13.54%, rgba(0, 0, 0, 0) 100%);
  z-index: 2;
}

.swiper::after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  width: 48px;
  background: linear-gradient(-90deg, #010101 13.54%, rgba(0, 0, 0, 0) 100%);
  z-index: 2;
}

@media only screen and (max-width: 767.95px) {
  .swiper {
    padding: 0 12px;
  }
}
