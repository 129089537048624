.blockPartners {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 136px;
  padding: 80px 0;
  background-image: url("./img/bg1920.svg");
  background-position: center;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.headerTitle {
  text-align: left;
  color: var(--color-text-inverted);

  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
}

.headerDesc {
  text-align: left;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-p4-medium-upp-comp-font-family);
  font-size: var(--typography-v3-p4-medium-upp-comp-font-size);
  line-height: var(--typography-v3-p4-medium-upp-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-upp-comp-font-weight);
  text-transform: var(--typography-v3-p4-medium-upp-comp-text-transform);
}

.contentInner {
  display: grid;
  grid-template-columns: 874px auto;
  margin-top: 72px;
  margin-left: -80px;
  gap: 4px;
}

.contentImg {
  width: 100%;
  height: auto;
}

.contentAdvantage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 116px;
}

.options {
  display: grid;
  grid-template-columns: minmax(223px, 1fr) minmax(223px, 1fr);
  row-gap: 60px;
  column-gap: 20px;
}

.optionItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.optionItemTitle {
  display: flex;
  align-items: center;
  height: 52px;
}

.optionItemTitleText {
  text-align: center;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}

.example {
  width: 100%;
  padding: 130px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.exampleTitle {
  text-align: center;
  color: var(--color-text-tertiary);
  font-family: var(--typography-v3-p4-medium-upp-comp-font-family);
  font-size: var(--typography-v3-p4-medium-upp-comp-font-size);
  line-height: var(--typography-v3-p4-medium-upp-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-upp-comp-font-weight);
  text-transform: var(--typography-v3-p4-medium-upp-comp-text-transform);
}

.exampleLogo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 1440px) and (max-width: 1919.95px) {
  .blockPartners {
    background-image: url("./img/bg1440.svg");
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439.95px) {
  .blockPartners {
    background-image: url("./img/bg1280.svg");
  }
  .contentInner {
    gap: 0px;
  }
  .options {
    grid-template-columns: minmax(168px, 1fr) minmax(203px, 1fr);
    column-gap: 4px;
  }
  .exampleLogo {
    gap: 16px;
  }
}

@media only screen and (max-width: 1279.95px) {
  .contentInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 72px;
    margin-left: 0;
  }
  .contentAdvantage {
    gap: 72px;
  }
  .example {
    padding: 72px 0 0 0;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1279.95px) {
  .blockPartners {
    background-image: url("./img/bg960.svg");
  }
  .options {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 32px;
  }
}

@media only screen and (max-width: 959.95px) {
  .blockPartners {
    background-image: url("./img/bg768.svg");
  }
}

@media only screen and (max-width: 767.95px) {
  .blockPartners {
    padding: 40px 0;
    background-image: url("./img/bg360.svg");
  }
  .contentInner {
    margin-top: 56px;
    gap: 56px;
  }
  .contentImg {
    width: auto;
    min-width: 100%;
    max-width: 398px;
  }
  .options {
    grid-template-columns: minmax(223px, 1fr);
    gap: 56px;
  }
  .optionItem {
    gap: 8px;
  }
  .example {
    padding: 80px 0 0 0;
  }
}
