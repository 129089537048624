.prices {
  flex-basis: 46%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.priceBlock,
.priceDiscountBlock {
  display: flex;
  align-items: center;
  gap: 16px;
}

.priceBlockTitle {
  min-width: 103px;
  display: flex;
  align-items: center;
  color: var(--color-text-secondary);
  text-align: left;
  font-family: var(--typography-v3-c1-regular-comp-font-family);
  font-size: var(--typography-v3-c1-regular-comp-font-size);
  line-height: var(--typography-v3-c1-regular-comp-line-height);
  font-weight: var(--typography-v3-c1-regular-comp-font-weight);
}

.priceDiscountBlockHeader {
  min-width: 103px;
  display: flex;
  align-items: center;
}

.priceDiscountBlockHeaderTitle {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-c1-medium-comp-font-family);
  font-size: var(--typography-v3-c1-medium-comp-font-size);
  line-height: var(--typography-v3-c1-medium-comp-line-height);
  font-weight: var(--typography-v3-c1-medium-comp-font-weight);
}

.priceDiscountBlockText {
  white-space: nowrap;
  font-family: var(--typography-v3-h3-comp-font-family);
  font-size: var(--typography-v3-h3-comp-font-size);
  line-height: var(--typography-v3-h3-comp-line-height);
  font-weight: var(--typography-v3-h3-comp-font-weight);
  color: var(--color-text-primary);
}

.priceBlockValue {
  display: flex;
  align-items: center;
}

.priceBlockValueText {
  text-align: left;
  color: var(--color-text-tertiary);
  white-space: nowrap;
  font-family: var(--typography-v3-p3-medium-comp-font-family);
  font-size: var(--typography-v3-p3-medium-comp-font-size);
  line-height: var(--typography-v3-p3-medium-comp-line-height);
  font-weight: var(--typography-v3-p3-medium-comp-font-weight);
}

.discounts {
  flex-basis: 54%;
  display: flex;
}
