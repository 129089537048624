.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.infoTitle {
  font-family: var(--typography-v3-h3-text-font-family);
  font-size: var(--typography-v3-h3-text-font-size);
  line-height: var(--typography-v3-h3-text-line-height);
  font-weight: var(--typography-v3-h3-text-font-weight);
  color: var(--color-text-primary);
  text-align: center;
}

.infoDesc {
  font-family: var(--typography-v3-p3-regular-text-font-family);
  font-size: var(--typography-v3-p3-regular-text-font-size);
  line-height: var(--typography-v3-p3-regular-text-line-height);
  font-weight: var(--typography-v3-p3-regular-text-font-weight);
  color: var(--color-text-secondary);
  text-align: center;
}
