.card {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-top: 40px;
  border-radius: 16px;
  box-shadow: var(--shadow-low);
  background-color: var(--color-background-primary);
}
.cardContent {
  display: flex;
  height: 459px;
  padding: 24px 0 19px 0;
}
.cardGroups {
  flex-basis: 65%;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
}
.cardTableDiscounts {
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
}
.cardFooter {
  min-height: 116px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-control-inactive);
  padding: 24px 0;
}
.cardFooterCalculations {
  flex-basis: 65%;
  display: flex;
  gap: 24px;
}
.cardFooterActions {
  flex-basis: 35%;
  display: flex;
  gap: 16px;
  padding-left: 40px;
  align-items: center;
}
