.blockCopyright {
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
}

.copyrightText {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-secondary);
}

@media only screen and (max-width: 767.95px) {
  .blockCopyright {
    flex-direction: column;
    padding: 20px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1439.95px) {
  .copyrightText {
    font-family: var(--typography-v3-p4-regular-comp-font-family);
    font-size: var(--typography-v3-p4-regular-comp-font-size);
    line-height: var(--typography-v3-p4-regular-comp-line-height);
    font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  }
}
