.blockListCalculation {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.listItem {
  display: grid;
  grid-template-columns: 160px minmax(300px, 1fr);
  column-gap: 32px;
  background-color: var(--color-constant-greyscale-100);
}
.listItemFirst {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.listItemLast {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.listItemPrice {
  display: flex;
  padding: 22px 0px 22px 40px;
}
.listItemPriceText {
  text-align: left;
  width: 120px;
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  color: var(--color-text-primary);
}
.listItemPriceText span {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p2-regular-comp-font-family);
  font-size: var(--typography-v3-p2-regular-comp-font-size);
  line-height: var(--typography-v3-p2-regular-comp-line-height);
  font-weight: var(--typography-v3-p2-regular-comp-font-weight);
}

.listItemInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 15px 24px 15px 0px;
}

.listItemInfoHeaderText {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
}

.listItemInfoText {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}

@media only screen and (max-width: 1279.95px) {
  .listItem {
    grid-template-columns: 190px 1fr;
    column-gap: 40px;
  }

  .listItemPrice {
    padding: 22px 0px 22px 36px;
  }

  .listItemInfo {
    padding: 15px 36px 15px 0px;
  }
}

@media only screen and (max-width: 959.95px) {
  .listItem {
    grid-template-columns: 148px 1fr;
    column-gap: 32px;
  }

  .listItemPrice {
    padding: 22px 0px 22px 28px;
  }
}

@media only screen and (max-width: 767.95px) {
  .blockListCalculation {
    margin: 0px -20px 0px;
  }
  .listItem {
    grid-template-columns: 140px minmax(192px, 1fr);
    column-gap: 8px;
  }

  .listItemPrice {
    padding: 22px 0px 22px 20px;
  }

  .listItemInfo {
    padding: 15px 20px 15px 0px;
    gap: 4px;
  }
}
