.group {
  min-width: 75px;
  display: flex;
  flex-direction: column;
}
.groupTitle {
  display: flex;
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-bold-comp-font-family);
  font-size: var(--typography-v3-p4-bold-comp-font-size);
  line-height: var(--typography-v3-p4-bold-comp-line-height);
  font-weight: var(--typography-v3-p4-bold-comp-font-weight);
}
.groupItems {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.groupItem {
  display: flex;
  gap: 8px;
}
.groupItemDuration {
  min-width: 40px;
  display: flex;
  white-space: nowrap;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}
.groupItemCount {
  display: flex;
  white-space: nowrap;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}
