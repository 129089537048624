.blockCard {
  min-height: 580px;
  display: grid;
  grid-template-columns: minmax(33.33%, 400px);
}

.card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  background-color: var(--color-background-primary);
  box-shadow: var(--shadow-low);
  border-radius: 16px;
  transition: box-shadow 0.3s ease-out;
}
.card:hover {
  box-shadow: var(--shadow-middle);
}

.cardBlock {
  display: flex;
  flex-direction: column;
}

.cardBlockTest {
  height: 100%;
}

.cardBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cardInfo {
  grid-column: 1/2;
  text-align: center;
  margin-top: 88px;
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  color: var(--color-text-primary);
}

.tariffInfo {
  min-height: 82px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 32px;
  text-align: center;
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  color: var(--color-text-primary);
}

.tariffPrice {
  font-family: var(--typography-v3-h3-wide-font-family);
  font-size: var(--typography-v3-h3-wide-font-size);
  line-height: var(--typography-v3-h3-wide-line-height);
  font-weight: var(--typography-v3-h3-wide-font-weight);
  color: var(--color-text-primary);
  text-align: center;
}

.tariffCalculations {
  min-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
}

.tariffCalculationsTest {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 44px;
  flex-grow: 1;
}

@media only screen and (max-width: 1439.95px) {
  .card {
    padding: 40px 24px;
  }
}
@media only screen and (max-width: 1279.95px) {
  .blockCard {
    min-height: auto;
    display: flex;
    flex: 1;
  }

  .card {
    padding: 40px 44px;
  }

  .cardBlockTest {
    display: grid;
    grid-template-columns: minmax(456px, calc(50% + 50px)) 1fr;
    grid-template-rows: repeat(5, auto);
    justify-items: flex-start;
  }

  .tariffInfo {
    margin-top: 24px;
    min-height: auto;
  }

  .tariffCalculationsTest {
    margin-top: 24px;
  }

  .cardBtnTest {
    grid-column: 2 / 3;
    grid-row: 1 / 5;
    align-self: center;
    margin-top: 0;
  }

  .cardInfo {
    margin-top: 52px;
  }
}

@media only screen and (max-width: 959.95px) {
  .card {
    padding: 40px 28px;
  }

  .cardBlockTest {
    grid-template-columns: minmax(360px, calc(50% + 44px)) 1fr;
  }

  .tariffInfo {
    margin-top: 24px;
  }

  .tariffCalculations {
    margin-top: 24px;
  }

  .cardBtn {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 767.95px) {
  .card {
    padding: 24px;
  }

  .cardBlockTest {
    display: flex;
  }

  .tariffInfo {
    margin-top: 77px;
  }

  .tariffCalculationsTest {
    margin-top: 93px;
  }

  .cardBtnTest {
    margin-top: 32px;
  }

  .cardInfo {
    margin-top: 250px;
  }
}
