.blockInfoIcon {
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
