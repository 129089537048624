.blockPlug {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plugContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}
.plugContentText {
  text-align: center;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-secondary);
}

.plugIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
