.block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.blockTitle {
  text-align: left;
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  color: var(--color-text-primary);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.listItem {
  display: flex;
  gap: 8px;
}

.discountInfo {
  min-width: 85px;
  display: flex;
  gap: 4px;
}

.discountInfoText {
  text-align: left;
  font-family: var(--typography-v3-c1-medium-comp-font-family);
  font-size: var(--typography-v3-c1-medium-comp-font-size);
  line-height: var(--typography-v3-c1-medium-comp-line-height);
  font-weight: var(--typography-v3-c1-medium-comp-font-weight);
  color: var(--color-text-primary);
}

.discountType {
  text-align: left;
  font-family: var(--typography-v3-c1-regular-comp-font-family);
  font-size: var(--typography-v3-c1-regular-comp-font-size);
  line-height: var(--typography-v3-c1-regular-comp-line-height);
  font-weight: var(--typography-v3-c1-regular-comp-font-weight);
  color: var(--color-text-primary);
}

@media only screen and (max-width: 1439.95px) {
  .listItem {
    flex-direction: column;
  }
}
