.blockPaymentCard {
  width: 100%;
  display: flex;
  min-height: 220px;
  padding: 28px 108px 28px 76px;
  border-radius: 16px;
  gap: 10px;
  box-shadow: var(--shadow-low);
  background-color: var(--color-background-primary);
  transition: box-shadow 0.2s ease-out;
}
.blockPaymentCard:hover {
  box-shadow: var(--shadow-middle);
}

.cardContent {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 5px;
}
.cardInfo {
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}
.cardDiscounts {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardPayGroups {
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.cardButtons {
  width: 184px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.btn {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dialogText {
  text-align: center;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}
