.option {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.optionItem {
  display: flex;
  align-items: center;
  gap: 16px;
}

.optionItemLabel {
  text-align: left;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}

.size_S_16 {
  width: 16px;
  height: 16px;
  &::before {
    width: 6px;
    height: 6px;
  }
}
.size_M_24 {
  width: 24px;
  height: 24px;
  &::before {
    width: 10px;
    height: 10px;
  }
}
.size_L_32 {
  width: 32px;
  height: 32px;
  &::before {
    width: 14px;
    height: 14px;
  }
}
.icon {
  border-radius: 50%;
  border: 2px solid;
  border-color: var(--color-control-inactive);
  background-color: var(--color-control-alternative);
  transition: 0.3s all ease-out;

  input:hover ~ & {
    background-color: var(--color-background-hover);
  }
  input:disabled ~ & {
    background: var(--color-background-hover);
  }
}
.checkedIcon {
  background-color: var(--color-control-primary-active);
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    border-radius: 50%;
    background-color: var(--color-control-alternative);
    content: "";
  }
  input:hover ~ & {
    background-color: var(--color-control-primary-active);
  }
  input:disabled ~ & {
    background: var(--color-background-hover);
  }
}
