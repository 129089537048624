.blockNotifications {
  display: flex;
  flex-direction: column;
}

.list {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
