.chip {
  display: flex;
  align-items: center;
  background-color: var(--color-control-tertiary-active);

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: 1px solid;
    border-radius: 2px;
    outline-color: var(--color-accent-active);
  }
}
.chipSizeS {
  height: 20px;
  border-radius: 4px;
  padding: 0px 8px;
  gap: 2px;
}
.chipSizeM {
  height: 28px;
  border-radius: 4px;
  padding: 0px 8px;
  gap: 4px;
}
.chipSizeL {
  height: 32px;
  border-radius: 6px;
  padding: 0px 12px;
  gap: 4px;
}

.text {
  color: var(--color-text-primary);
}

.textSizeS {
  font-family: var(--typography-v3-c1-regular-comp-font-family);
  font-size: var(--typography-v3-c1-regular-comp-font-size);
  line-height: var(--typography-v3-c1-regular-comp-line-height);
  font-weight: var(--typography-v3-c1-regular-comp-font-weight);
}

.textSizeM {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}

.textSizeL {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}
