.blockTariffCardOptions {
  min-height: 144px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 24px;
}
.cardOption {
  text-align: start;
}
.cardOptionText {
  font-family: var(--typography-v3-p3-regular-text-font-family);
  font-size: var(--typography-v3-p3-regular-text-font-size);
  line-height: var(--typography-v3-p3-regular-text-line-height);
  font-weight: var(--typography-v3-p3-regular-text-font-weight);
  color: var(--color-text-primary);
}

@media only screen and (max-width: 1279.95px) {
  .blockTariffCardOptions {
    min-height: auto;
    grid-column: 1/2;
  }
}
@media only screen and (max-width: 959.95px) {
  .cardOptionText {
    font-family: var(--typography-v3-p4-regular-text-font-family);
    font-size: var(--typography-v3-p4-regular-text-font-size);
    line-height: var(--typography-v3-p4-regular-text-line-height);
    font-weight: var(--typography-v3-p4-regular-text-font-weight);
  }
}
@media only screen and (max-width: 767.95px) {
  .blockTariffCardOptions {
    min-height: 128px;
  }
}
