.blockTable {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tableRow {
  display: grid;
  padding: 12px 0;
  grid-template-columns: 1fr 80px 80px;
  gap: 24px;
  border-bottom: 1px solid;
  border-bottom-color: var(--color-constant-greyscale-300);
}

.tableRowHeadCell,
.tableRowCell {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-bold-comp-font-family);
  font-size: var(--typography-v3-p4-bold-comp-font-size);
  line-height: var(--typography-v3-p4-bold-comp-line-height);
  font-weight: var(--typography-v3-p4-bold-comp-font-weight);
}

.tableRowCellLabel {
  text-align: left;
  color: var(--color-text-secondary);
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
}

.tableRowCellRegular {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}

@media only screen and (max-width: 1439.95px) {
  .blockTable {
    padding-right: 12px;
  }

  .tableRow {
    grid-template-columns: 1fr 74px 74px;
    gap: 22px;
  }
}

@media only screen and (max-width: 1279.95px) {
  .blockTable {
    padding: 0px;
  }

  .tableRow {
    grid-template-columns: minmax(202px, 1fr) 126px 152px;
    gap: 26px;
  }
}

@media only screen and (max-width: 959.95px) {
  .tableRow {
    grid-template-columns: minmax(148px, 1fr) 88px 88px;
    gap: 32px;
  }
}

@media only screen and (max-width: 767.95px) {
  .tableRow {
    grid-template-columns: minmax(154px, 1fr) 83px 83px;
    gap: 0px;
  }
}
