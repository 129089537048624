.blockCrossedOutText {
  position: relative;
}
.line {
  position: absolute;
  right: -2px;
  top: 47%;
  left: -2px;
  bottom: 0px;
  height: 1px;
  transform: rotate(-4deg);
}
