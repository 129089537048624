.message {
  display: flex;
  align-items: center;
  margin-top: 24px;
  width: 100%;
}

.messageText {
  display: flex;
  align-items: center;
}
