.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--color-background-overlay);
  display: flex;
  flex-direction: column-reverse;
  z-index: 1300;
}

.bgOpen {
  animation-name: bgOpen;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes bgOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bgClose {
  animation-name: bgClose;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
@keyframes bgClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dialogBlock {
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-primary);
}

.dialogBlockOpen {
  animation-name: dialogOpen;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes dialogOpen {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(50%);
    opacity: 0;
  }
  75% {
    transform: translateY(25%);
    opacity: 0.1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.dialogBlockClose {
  animation-name: dialogClose;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes dialogClose {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  25% {
    transform: translateY(25%);
    opacity: 0.1;
  }
  75% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.header {
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.nav {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.navTabsAuth {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.navTabsAuthDevider {
  width: 100%;
  height: 1px;
  background-color: var(--color-background-stroke);
  margin: 4px 20px;
}

.services {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px 16px 20px;
  box-shadow: var(--shadow-drop);
  background-color: var(--color-background-modal);
}

.footer {
  min-height: 60px;
  width: 100%;
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-background-secondary);
}
