.info {
  width: 100%;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-constant-blueberry-lightest);
  border-radius: 8px;
  padding: 0px 22px;
  gap: 15px;
}

.infoContent {
  display: flex;
  gap: 8px;
  align-items: center;
}

.infoContentText {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-primary);
}
