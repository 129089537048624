.tableContainer {
  max-width: 100%;
  margin-top: 32px;
}

.tableWrap {
  display: block;
  max-width: 100%;
}

.table {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  & [data-sticky-td] {
    position: sticky;
  }

  & [data-sticky-first-right-td] {
    background: linear-gradient(
      270deg,
      #ffffff 70%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  & [data-sticky-last-left-td] {
    background: linear-gradient(
      90deg,
      #ffffff 82.5%,
      rgba(255, 255, 255, 0) 115%
    );
  }
}

.thead {
  width: 100%;
  height: var(--table-row-height);
  z-index: 1;
}

.theadTR {
  min-width: 100%;
  display: flex;
  height: var(--table-row-height);
  border-bottom: 1px solid;
  border-bottom-color: var(--color-control-inactive);
}

.tbodyTR {
  min-width: 100%;
  display: flex;
  height: var(--table-row-height);
  border-bottom: 1px solid;
  border-bottom-color: var(--color-control-inactive);
}

.th {
  text-align: left;
  background-color: var(--color-background-primary);
  overflow: hidden;
  display: flex !important;
  align-items: center;
  padding-left: 12px;
}

.theadTypography {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
  color: var(--color-text-primary);
}

.tbody {
  width: 100%;
  position: relative;
  z-index: 0;
  background-color: var(--color-background-primary);
}

.td {
  max-height: var(--table-row-height);
  text-align: left;
  overflow: hidden;
  background-color: var(--color-background-primary);
  display: flex !important;
  align-items: center;
  padding-left: 12px;
}

.blank {
  display: flex;
  flex-grow: 1;
}

.checkbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  padding: 0 12px;
}
