.mts-map-custom-popup-header {
  max-width: 90%;
  display: flex;
  flex-grow: 1;
}

.mts-map-custom-popup-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}

.mts-map-custom-popup-info__status {
  display: flex;
  align-items: center;
  gap: 6px;
}

.mts-map-custom-popup-info__location {
  display: flex;
  flex-wrap: wrap;
}

.mts-map-custom-popup-info__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mts-map-custom-popup-info__list__item {
  display: flex;
  gap: 5px;
}

.mts-map-custom-popup-button {
  width: 100%;
  height: 32px;
  padding: 0px 12px;
  border-radius: 6px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-control-tertiary-active);
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
  color: var(--color-text-primary);
  transition: transform 0.3s linear;
}
.mts-map-custom-popup-button:hover:not([disabled]) {
  cursor: pointer;
  transform: scale(1.03);
}
.mts-map-custom-popup-button:disabled {
  background: var(--color-control-inactive);
  color: var(--color-text-tertiary);
  cursor: initial;
}

.mts-map-custom-popup-msg {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin-top: 20px;
}

/* NOTE: Close icon */
.leaflet-popup-close-button {
  margin: 24px 24px 0 0 !important;
  color: var(--color-icon-primary) !important;
  transform: scale(1.3) !important;
}

/* NOTE: Zoom buttons */
.leaflet-top {
  bottom: 0;
}

.leaflet-top .leaflet-control-zoom {
  top: 50%;
  transform: translateY(-50%);
}

.leaflet-popup-content {
  margin: 0px !important;
  line-height: 1 !important;
  padding: 24px;
}

.leaflet-control-zoom {
  margin-right: 12px !important;
  margin-top: 0 !important;
  border: none !important;
  box-shadow: var(--shadow-low) !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  border-bottom: none !important;
  background-color: var(--color-constant-greyscale-0) !important;
}

.leaflet-control-zoom-in {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.leaflet-control-zoom-out {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.leaflet-popup-content p {
  margin: 0px 0px !important;
}
