.blockFilter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: 940px;
  gap: 10px;
}
.filterSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 292px;
  min-width: 215px;
}
.filterAutocomplete {
  display: flex;
  width: 100%;
  max-width: 292px;
  min-width: 150px;
}
