.block {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  transform: translate(-150px, -125px);
}

.contentInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contentInfoHeader {
  text-align: left;

  font-family: var(--typography-v3-promo2-short-wide-font-family);
  font-size: var(--typography-v3-promo2-short-wide-font-size);
  line-height: var(--typography-v3-promo2-short-wide-line-height);
  font-weight: var(--typography-v3-promo2-short-wide-font-weight);
  text-transform: var(--typography-v3-promo2-short-wide-text-transform);
  color: var(--color-text-primary);
}
.contentInfoTitle {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}
.contentInfoDesc {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}

.img {
  position: absolute;
  width: 89%;
  height: auto;
}

.contentBtn {
  display: flex;
}

@media only screen and (max-width: 1279.95px) {
  .content {
    transform: translate(0, 0);
  }

  .img {
    top: 60%;
  }
}
