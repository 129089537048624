.blockTestAcessMessage {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text {
  text-align: center;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}
