.blockIndicator {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-accent-warning);
}
.indicatorComplete {
  background-color: var(--color-accent-positive);
}
.indicatorError {
  background-color: var(--color-text-negative);
}
.indicatorDeleted {
  background-color: var(--color-control-inactive);
}
.text {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}
.textInactive {
  color: var(--color-text-tertiary);
}
