.blockMTSAutocomplete {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.inputLabel {
  width: 100%;
  display: flex;
  margin-bottom: 4px;
}
.inputError {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(20px);
}
.inputWrap {
  display: flex;
  flex-grow: 1;
  align-items: center;
  background-color: var(--color-background-secondary);
  border: 1px solid;
  border-color: var(--color-background-stroke);
  overflow: hidden;
  transition: 0.3s;
}
.inputWrapEffects {
  &:hover {
    border-color: var(--color-control-secondary-active);
  }
  &:focus-within {
    border-color: var(--color-accent-active);
  }
}
.inputWrapSizeS {
  height: 32px;
  border-radius: 6px;
}
.inputWrapSizeM {
  height: 44px;
  border-radius: 8px;
}
.inputWrapSizeL {
  height: 52px;
  border-radius: 8px;
}
.inputWrapSizeXL {
  height: 72px;
  border-radius: 10px;
}
.inputWrapError {
  border-color: var(--color-accent-negative);
}
.inputWrapDisabed {
  background-color: var(--color-background-disabled);
  border-color: var(--color-background-stroke-disabled);
}

.inputLabelWrapSizeXL {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.input {
  width: 100%;
  background-color: transparent;
  height: 100%;
  outline: none;
  transition: 0.2s;
  color: var(--color-text-primary);

  &::placeholder {
    color: var(--color-text-secondary);
  }
}
.inputSizeS {
  padding: 6px 8px 6px 12px;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}
.inputSizeM {
  padding: 10px 8px 10px 12px;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}
.inputSizeL {
  padding: 14px 8px 14px 12px;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}
.inputSizeXL {
  padding: 24px 8px 24px 12px;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}
.inputSizeXLHidden {
  opacity: 0;
}
.inputSizeXLFocused {
  padding: 34px 8px 14px 12px;
  opacity: 1;
}
.inputDisabled:disabled {
  color: var(--color-text-secondary);
}
.buttons {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 8px;
}
.labelSizeXL {
  position: absolute;
  left: 12px;
  transition: 0.2s;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.labelSizeXLFocused {
  top: 14px;
  transform: translateY(0%);
}
.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.textOption {
  flex-grow: 1;
  color: var(--color-text-primary);
}
.textNoOption {
  color: var(--color-text-tertiary);
  text-align: center;
}
.textOptionSizeS {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}
.textOptionSizeM,
.textOptionSizeL,
.textOptionSizeXL {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}

.optionCheckbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
