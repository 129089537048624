.blockAbout {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 136px;
  gap: 72px;
}
.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}

.infoTitle {
  text-align: left;
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
  color: var(--color-text-primary);
}

.infoDesc {
  text-align: left;
  font-family: var(--typography-v3-p4-medium-upp-comp-font-family);
  font-size: var(--typography-v3-p4-medium-upp-comp-font-size);
  line-height: var(--typography-v3-p4-medium-upp-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-upp-comp-font-weight);
  text-transform: var(--typography-v3-p4-medium-upp-comp-text-transform);
  color: var(--color-text-primary);
}

.cards {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  gap: 32px;
}
.card {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.cardHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
.headerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.headerInfoStep {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 2px 6px;
  width: 52px;
  background-color: var(--color-background-secondary);
  border-radius: 6px;
}
.headerInfoStepText {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
}

.headerInfoTitle {
  min-height: 84px;
  text-align: center;
  font-family: var(--typography-v3-h3-wide-font-family);
  font-size: var(--typography-v3-h3-wide-font-size);
  line-height: var(--typography-v3-h3-wide-line-height);
  font-weight: var(--typography-v3-h3-wide-font-weight);
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cardContentText {
  text-align: center;
  font-family: var(--typography-v3-p3-regular-text-font-family);
  font-size: var(--typography-v3-p3-regular-text-font-size);
  line-height: var(--typography-v3-p3-regular-text-line-height);
  font-weight: var(--typography-v3-p3-regular-text-font-weight);
}

@media only screen and (max-width: 1279.95px) {
  .headerInfoTitle {
    min-height: 112px;
  }
}

@media only screen and (max-width: 959.95px) {
  .cards {
    flex-direction: column;
    align-items: center;
    gap: 56px;
  }
  .headerInfoTitle {
    min-height: auto;
  }
}
