.panel {
  min-width: 280px;
  width: auto;
  height: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
}

.panelSearch {
  display: flex;
  min-width: 100%;
  z-index: inherit;
}

.panelTitle {
  display: flex;
  width: 100%;
  z-index: inherit;
  margin-top: 24px;
  text-align: left;
  font-family: var(--typography-v3-p3-medium-comp-font-family);
  font-size: var(--typography-v3-p3-medium-comp-font-size);
  line-height: var(--typography-v3-p3-medium-comp-line-height);
  font-weight: var(--typography-v3-p3-medium-comp-font-weight);
  color: var(--color-text-primary);
}

.panelItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 12px;
}

.panelItemsDivider {
  height: 1px;
  width: 100%;
  background-color: var(--color-control-inactive);
}

.buttonIntro {
  width: 100%;
  min-height: calc(44px + 24px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.sectionCoverage {
  width: 100%;
  margin-top: 12px;
}

.sectionCoverageInner {
  margin-top: 12px;
}

.sectionCoverageSubmenu {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  margin-left: 16px;
}
