.dialogImage {
  display: flex;
  justify-content: center;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.dialogContentText {
  font-family: var(--typography-v3-p3-bold-comp-font-family);
  font-size: var(--typography-v3-p3-bold-comp-font-size);
  line-height: var(--typography-v3-p3-bold-comp-line-height);
  font-weight: var(--typography-v3-p3-bold-comp-font-weight);
  color: var(--color-text-primary);
  text-align: center;
}

.dialogContentDesc {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
  text-align: center;
}
