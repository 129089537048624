.card {
  position: relative;
  width: 100%;
  height: 320px;
  max-width: 292px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-low);
  padding: 40px 32px;
  border-radius: 16px;
  background-color: var(--color-background-primary);
  outline: none;
  transition: all 0.2s ease-out;

  &:focus-visible {
    outline: none;
    outline-color: transparent;
    border-radius: 16px;
  }

  &:hover {
    box-shadow: var(--shadow-middle);
  }

  &:focus {
    box-shadow: var(--shadow-middle);
  }
}

.title {
  text-align: left;
  color: var(--color-text-primary);
}

.titleSizeS {
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}

.titleSizeM,
.titleSizeL {
  font-family: var(--typography-v3-h3-wide-font-family);
  font-size: var(--typography-v3-h3-wide-font-size);
  line-height: var(--typography-v3-h3-wide-line-height);
  font-weight: var(--typography-v3-h3-wide-font-weight);
}

.titleSizeXL {
  font-family: var(--typography-v3-h2-wide-font-family);
  font-size: var(--typography-v3-h2-wide-font-size);
  line-height: var(--typography-v3-h2-wide-line-height);
  font-weight: var(--typography-v3-h2-wide-font-weight);
}

.descriptionText {
  text-align: left;
  color: var(--color-text-secondary);
}

.descriptionTextSizeS {
  font-family: var(--typography-v3-c1-regular-comp-font-family);
  font-size: var(--typography-v3-c1-regular-comp-font-size);
  line-height: var(--typography-v3-c1-regular-comp-line-height);
  font-weight: var(--typography-v3-c1-regular-comp-font-weight);
}

.descriptionTextSizeM,
.descriptionTextSizeL {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}

.descriptionTextSizeXL {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}

.cardDescription {
  display: flex;
  margin-top: 16px;
}
.cardContent {
  display: flex;
  width: 100%;
  height: 100%;
}
