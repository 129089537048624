:root {
  --button-slide-size: 64px;
  --button-slide-size-medium: 56px;
  --button-slide-size-small: 48px;
}

.btn {
  position: absolute;
  bottom: 48px;
  width: var(--button-slide-size);
  height: var(--button-slide-size);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}

.btnNext {
  right: calc(((100vw - 1440px) / 2) + 88px);
}

.btnPrev {
  right: calc(((100vw - 1440px) / 2) + 88px + var(--button-slide-size) + 12px);
}

.btnContent {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.btnContentCircleProgress {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1439.95px) {
  .btnNext {
    right: 56px;
  }

  .btnPrev {
    right: calc(56px + var(--button-slide-size) + 12px);
  }
}

@media only screen and (max-width: 1279.95px) {
  .btnNext {
    right: 40px;
  }

  .btnPrev {
    right: calc(40px + var(--button-slide-size) + 12px);
  }
}

@media only screen and (max-width: 959.95px) {
  .btn {
    width: var(--button-slide-size-medium);
    height: var(--button-slide-size-medium);
  }
  .btnPrev {
    right: calc(40px + var(--button-slide-size-medium) + 12px);
  }
}

@media only screen and (max-width: 767.95px) {
  .btn {
    width: var(--button-slide-size-small);
    height: var(--button-slide-size-small);
    bottom: 16px;
  }
  .btnNext {
    left: calc(50% + 6px);
  }
  .btnPrev {
    left: calc(50% - var(--button-slide-size-small) - 6px);
  }
}
