.blockInputSearch {
  position: relative;
  width: 100%;
  height: 44px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background-secondary);
  border-radius: 8px;
}

.input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  outline: none;
  border: 1px solid;
  border-color: var(--color-background-stroke);
  border-radius: 8px;
  padding: 0px 44px;
  background-color: transparent;
  transition: 0.3s all ease-out;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}
.input::placeholder {
  color: var(--color-text-secondary);
}
.input:hover {
  border-color: var(--color-control-secondary-active);
}
.input:focus {
  border-color: var(--color-accent-active);
}
