.blockMobileBar {
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow-x: hidden;
  height: var(--mobile-bar-height);
  display: flex;
  align-items: center;
  background-color: var(--color-background-primary-elevated);
  padding: 0 12px;
  z-index: 1100;
  transform: translateY(0px);
  box-shadow: var(--shadow-drop);
}

.blockMobileBarShow {
  transform: translateY(var(--mobile-bar-height));
}

.links {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 16px;
  overflow-x: auto;
  gap: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
