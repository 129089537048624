.blockAdvantages {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 136px;
}
.title {
  width: 100%;
  display: flex;
  flex-grow: 1;
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
}
.cards {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(0, 400px) minmax(0, 400px) minmax(0, 400px);
  row-gap: 56px;
  column-gap: 32px;
  margin-top: 56px;
}
.card {
  display: flex;
  flex-direction: column;
}

.cardIcon {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardTitle {
  min-height: 56px;
  width: 100%;
  justify-content: center;
  margin-top: 8px;
}

.cardTitleText {
  text-align: center;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-h3-wide-font-family);
  font-size: var(--typography-v3-h3-wide-font-size);
  line-height: var(--typography-v3-h3-wide-line-height);
  font-weight: var(--typography-v3-h3-wide-font-weight);
}

.cardInfo {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 12px;
}

.cardInfoText {
  text-align: center;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-regular-text-font-family);
  font-size: var(--typography-v3-p3-regular-text-font-size);
  line-height: var(--typography-v3-p3-regular-text-line-height);
  font-weight: var(--typography-v3-p3-regular-text-font-weight);
}

@media only screen and (max-width: 1439.95px) {
  .cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .cardTitle {
    min-height: 86px;
  }
}

@media only screen and (min-width: 960px) {
  .cardDescriptionThird {
    display: inline-block;
  }
  .cardDescriptionFourth {
    display: inline-block;
  }
}

@media only screen and (max-width: 959.95px) {
  .cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 767.95px) {
  .title {
    font-family: var(--typography-v3-h1-wide-font-family);
    font-size: var(--typography-v3-h1-wide-font-size);
    line-height: var(--typography-v3-h1-wide-line-height);
    font-weight: var(--typography-v3-h1-wide-font-weight);
  }

  .cards {
    grid-template-columns: 1fr;
    max-width: 400px;
    align-self: center;
  }
  .cardTitle {
    min-height: auto;
  }
}
