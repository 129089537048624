.blockMTSFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-background-secondary);
  margin: 188px 0 0 0;
}

.blockMTSFooterMobile {
  padding-bottom: 56px;
}

@media only screen and (max-width: 767.95px) {
  .blockMTSFooter {
    margin: 144px 0 0 0;
  }
}
