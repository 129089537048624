.bg {
  position: fixed;
  height: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-background-overlay);
  display: flex;
  flex-direction: column-reverse;
  z-index: 1300;
}
.bgOpen {
  animation-name: bgOpen;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes bgOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bgClose {
  animation-name: bgClose;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
@keyframes bgClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.dialogHeader {
  position: relative;
  width: 100%;
  min-height: 64px;
  max-height: 64px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 20px 20px 12px 20px;
}
.dialogHeaderTitle {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-h4-comp-font-family);
  font-size: var(--typography-v3-h4-comp-font-size);
  line-height: var(--typography-v3-h4-comp-line-height);
  font-weight: var(--typography-v3-h4-comp-font-weight);
}

.dialogContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  overflow-y: auto;
}
.dialogBlock {
  height: auto;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-primary);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.dialogBlockOpen {
  animation-name: dialogOpen;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes dialogOpen {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(50%);
    opacity: 0;
  }
  75% {
    transform: translateY(25%);
    opacity: 0.1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.dialogBlockClose {
  animation-name: dialogClose;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes dialogClose {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  25% {
    transform: translateY(25%);
    opacity: 0.1;
  }
  75% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
