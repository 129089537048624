:root {
  --table-row-height: 44px;
  --header-top-section-height: 52px;
  --header-bottom-section-height: 52px;
  --header-desktop-height: calc(
    var(--header-top-section-height) + var(--header-bottom-section-height)
  );
  --container-padding: 184px;
  --container-width: 1920px;
  --mobile-bar-height: 56px;
  --footer-personal-area-height: 68px;
  --mobile-bar-height: 56px;
  --shadow-drop: inset 0px 0.2px 0px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1919.95px) {
  :root {
    --container-width: 100%;
    --container-padding: 160px;
  }
}

@media screen and (max-width: 1679.95px) {
  :root {
    --container-padding: 88px;
  }
}

@media screen and (max-width: 1439.95px) {
  :root {
    --container-padding: 56px;
  }
}

@media screen and (max-width: 1279.95px) {
  :root {
    --container-padding: 40px;
  }
}

@media screen and (max-width: 767.95px) {
  :root {
    --container-padding: 20px;
    --footer-personal-area-height: 64px;
  }
}
