.card {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-low);
  border-radius: 16px;
  background-color: var(--color-background-primary);
  outline: none;
  transition: all 0.2s ease-out;

  &:focus-visible {
    outline: none;
    outline-color: transparent;
    border-radius: 16px;
  }

  &:hover {
    box-shadow: var(--shadow-middle);
  }

  &:focus {
    box-shadow: var(--shadow-middle);
  }
}
