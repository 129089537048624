.blockResolveCases {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 120px 0 0 0;
}

.title {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
}

.blockResolveCasesContent {
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 460px);
  gap: 32px;
}
.cardContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cardContentTitle {
  color: var(--color-text-primary);
  text-align: left;
  text-transform: uppercase;
  min-height: 56px;
  font-family: var(--typography-v3-h3-wide-font-family);
  font-size: var(--typography-v3-h3-wide-font-size);
  line-height: var(--typography-v3-h3-wide-line-height);
  font-weight: var(--typography-v3-h3-wide-font-weight);
}

.cardContentList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  margin-top: 16px;
}

.cardContentListItem {
  display: flex;
}

.cardContentListItemText {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-regular-text-font-family);
  font-size: var(--typography-v3-p4-regular-text-font-size);
  line-height: var(--typography-v3-p4-regular-text-line-height);
  font-weight: var(--typography-v3-p4-regular-text-font-weight);
}

.cardContentListItemTextLink {
  text-align: left;
  color: inherit;
  font-family: var(--typography-v3-p4-regular-text-font-family);
  font-size: var(--typography-v3-p4-regular-text-font-size);
  line-height: var(--typography-v3-p4-regular-text-line-height);
  font-weight: var(--typography-v3-p4-regular-text-font-weight);
}

.cases {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.case {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.caseTitle {
  text-align: center;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}

@media only screen and (max-width: 1439.95px) {
  .cards {
    grid-template-rows: repeat(2, 448px);
  }
}

@media only screen and (max-width: 1279.95px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 428px);
  }

  .caseTitle {
    font-family: var(--typography-v3-p3-medium-text-font-family);
    font-size: var(--typography-v3-p3-medium-text-font-size);
    line-height: var(--typography-v3-p3-medium-text-line-height);
    font-weight: var(--typography-v3-p3-medium-text-font-weight);
  }
}

@media only screen and (max-width: 959.95px) {
  .cards {
    grid-template-columns: repeat(2, minmax(328px, 1fr));
    grid-template-rows: repeat(3, 480px);
  }

  .cardContentTitle {
    min-height: 84px;
  }

  .caseTitle {
    font-family: var(--typography-v3-p4-medium-comp-font-family);
    font-size: var(--typography-v3-p4-medium-comp-font-size);
    line-height: var(--typography-v3-p4-medium-comp-line-height);
    font-weight: var(--typography-v3-p4-medium-comp-font-weight);
  }
}

@media only screen and (max-width: 767.95px) {
  .cards {
    grid-template-columns: 320px;
    grid-template-rows: repeat(6, 456px);
    justify-content: center;
    gap: 20px;
  }

  .cardContentList {
    margin-top: 8px;
  }

  .cases {
    grid-template-columns: 320px;
    justify-content: center;
    gap: 56px;
  }

  .caseTitle {
    font-family: var(--typography-v3-h4-wide-font-family);
    font-size: var(--typography-v3-h4-wide-font-size);
    line-height: var(--typography-v3-h4-wide-line-height);
    font-weight: var(--typography-v3-h4-wide-font-weight);
  }
}
