.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.listRow {
  display: flex;
  justify-content: flex-end;
  animation: listRow 1s;
}
.toast {
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 10px;
  border-radius: 12px;
  background-color: var(--color-background-inverted);
  gap: 6px;
}
.toastIcon {
  display: flex;
  align-self: flex-start;
}
.toastContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.toastContentTitle {
  font-family: var(--typography-v3-p3-bold-comp-font-family);
  font-size: var(--typography-v3-p3-bold-comp-font-size);
  line-height: var(--typography-v3-p3-bold-comp-line-height);
  font-weight: var(--typography-v3-p3-bold-comp-font-weight);
  text-align: start;
  color: var(--color-text-inverted);
}
.toastContentText {
  display: flex;
  text-align: start;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}

@keyframes listRow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
