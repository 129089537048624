.blockMTSTabs {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.blockMTSTabsSizeS {
  gap: 16px;
}
.blockMTSTabsSizeM {
  gap: 24px;
}
.blockMTSTabsSizeL {
  gap: 24px;
}

.tab {
  display: flex;
  align-items: center;
}

.tab:hover {
  cursor: pointer;
}

.tab:focus-visible {
  outline: 1px solid;
  border-radius: 2px;
  outline-color: var(--color-accent-active);
}

.tabSizeS {
  height: 52px;
}
.tabSizeM {
  height: 52px;
}
.tabSizeL {
  height: 72px;
}

.tabSelected {
  position: relative;
}
.tabSelected::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: var(--color-control-primary-active);
  border-radius: 1px;
  animation: selectedTab 0.3s ease-out;
}

@keyframes selectedTab {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.tabLabel {
  margin: 0 2px;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-medium-comp-font-family);
  font-size: var(--typography-v3-p3-medium-comp-font-size);
  line-height: var(--typography-v3-p3-medium-comp-line-height);
  font-weight: var(--typography-v3-p3-medium-comp-font-weight);
}

.tabLabelSizeS {
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
}
.tabLabelSizeM {
  font-family: var(--typography-v3-p3-medium-comp-font-family);
  font-size: var(--typography-v3-p3-medium-comp-font-size);
  line-height: var(--typography-v3-p3-medium-comp-line-height);
  font-weight: var(--typography-v3-p3-medium-comp-font-weight);
}
.tabLabelSizeL {
  font-family: var(--typography-v3-h4-comp-font-family);
  font-size: var(--typography-v3-h4-comp-font-size);
  line-height: var(--typography-v3-h4-comp-line-height);
  font-weight: var(--typography-v3-h4-comp-font-weight);
}

.tabCount {
  height: 16px;
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 4px;
  border-radius: 50%;
  margin-left: 4px;
  background-color: var(--color-background-secondary);
  font-family: var(--typography-v3-c1-medium-comp-font-family);
  font-size: var(--typography-v3-c1-medium-comp-font-size);
  line-height: var(--typography-v3-c1-medium-comp-line-height);
  font-weight: var(--typography-v3-c1-medium-comp-font-weight);
  color: var(--color-text-primary);
}
