.app {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background-color: var(--color-background-primary);
  overflow-x: hidden;
}

.main {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--header-desktop-height);
}
