.headerTitle,
.infoAddressLabel {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-bold-comp-font-family);
  font-size: var(--typography-v3-p4-bold-comp-font-size);
  line-height: var(--typography-v3-p4-bold-comp-line-height);
  font-weight: var(--typography-v3-p4-bold-comp-font-weight);
}

.infoAddressText,
.infoDescription {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}
