.bg {
  position: absolute;
  top: var(--header-top-section-height);
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: var(--color-background-overlay);
  -webkit-box-shadow: var(--shadow-low);
  -moz-box-shadow: var(--shadow-low);
  box-shadow: var(--shadow-low);
  display: flex;
  flex-direction: column;
}

.bgOpen {
  animation-name: bgOpen;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes bgOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltip {
  position: absolute;
  top: 22px;
  height: auto;
  background-color: var(--color-background-primary-elevated);
  border-radius: 40px;
  padding: 48px;
  -webkit-box-shadow: var(--shadow-low);
  -moz-box-shadow: var(--shadow-low);
  box-shadow: var(--shadow-low);
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tooltipOpen {
  animation-name: tooltipOpen;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes tooltipOpen {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltipArrow {
  position: absolute;
  top: 15px;
  transition: 0.3s;
}

.tooltipArrow:before {
  display: block;
  content: "";
  width: 0px;
  height: 0px;
  background-color: transparent;
  border-top: 0px solid transparent;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid;
  border-bottom-color: var(--color-background-primary-elevated);
}

.tooltipArrowOpen {
  animation-name: tooltipArrowOpen;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes tooltipArrowOpen {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.btn {
  display: grid;
  grid-template-columns: 48px minmax(250px, 320px);
  column-gap: 16px;

  &:focus-visible {
    outline: 1px solid;
    border-radius: 2px;
    outline-color: var(--color-accent-active);
  }

  &:hover {
    cursor: pointer;
  }
}

.btnInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.btnInfoLabel {
  font-family: var(--typography-v3-p3-bold-comp-font-family);
  font-size: var(--typography-v3-p3-bold-comp-font-size);
  line-height: var(--typography-v3-p3-bold-comp-line-height);
  font-weight: var(--typography-v3-p3-bold-comp-font-weight);
  color: var(--color-text-primary);
  text-align: left;
}

.btnInfoDesc {
  font-family: var(--typography-v3-p4-regular-text-font-family);
  font-size: var(--typography-v3-p4-regular-text-font-size);
  line-height: var(--typography-v3-p4-regular-text-line-height);
  font-weight: var(--typography-v3-p4-regular-text-font-weight);
  color: var(--color-text-secondary);
  text-align: left;
}
