.blockIcons {
  display: flex;
  flex-direction: column;
}

.list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.listItem {
  display: flex;
  flex-basis: 24%;
  gap: 10px;
  align-items: center;
  height: 40px;
}

.listItemText {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p2-regular-text-font-family);
  font-size: var(--typography-v3-p2-regular-text-font-size);
  line-height: var(--typography-v3-p2-regular-text-line-height);
  font-weight: var(--typography-v3-p2-regular-text-font-weight);
}
