.blockMobileMenuNav {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.navLink {
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}

.navLinkChild {
  min-height: 36px;
}

.navLinkText {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-primary);
  text-align: left;
}

.navLinkTextChild {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-secondary-link);
}
