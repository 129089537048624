.header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: var(--header-desktop-height);
  display: grid;
  grid-template-rows: var(--header-top-section-height);
  background-color: var(--color-background-secondary);
  border-bottom: 1px solid;
  border-bottom-color: var(--color-background-stroke);
  z-index: 1100;
}

.headerAuthorized {
  height: var(--header-desktop-height);
  grid-template-rows: var(--header-top-section-height) var(
      --header-bottom-section-height
    );
}

.headerContent {
  position: relative;
  width: var(--container-width);
  padding: 0px var(--container-padding);
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: space-between;
  gap: 28px;
}

.headerContentBlock {
  display: flex;
  flex-grow: 1;
}

.headerContentBlockLogo {
  display: flex;
  align-self: center;
}

.headerContentBlockTabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 44px;
  flex-grow: 1;
}

.headerContentAuth {
  display: flex;
  gap: 16px;
}

.headerContentBottom {
  width: var(--container-width);
  padding: 0px var(--container-padding);
  display: flex;
  align-items: center;
  justify-self: center;
}

.headerContentBottomBlock {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  border-top: 1px solid;
  border-top-color: var(--color-background-stroke);
}

.headerContentBottomBlockTabs {
  display: flex;
  align-items: center;
}

.headerContentBottomUser {
  height: 100%;
  display: flex;
  align-items: center;
}
