#app-notification-bar-element {
  position: fixed;
  right: calc(50vw - (1920px / 2) + 184px);
  bottom: 104px;
  z-index: 2000;
  width: auto;
}

@media only screen and (max-width: 1919.95px) {
  #app-notification-bar-element {
    right: var(--container-padding);
    margin-left: var(--container-padding);
  }
}
