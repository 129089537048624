.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 8px 0;
}
.listElem {
  display: flex;
  border-bottom: 1px solid;
  border-bottom-color: var(--color-background-stroke);
  padding: 5.5px 12px;
}
.listElemCount {
  flex-basis: 65%;
  display: flex;
  align-items: center;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
  text-align: left;
}
.listElemDiscountSize {
  flex-basis: 35%;
  display: flex;
  align-items: center;
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
  color: var(--color-text-primary);
  text-align: left;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
