.infoPayment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.infoPaymentPrices {
  display: flex;
  align-items: center;
  gap: 12px;
}

.infoPaymentPricesNumber {
  white-space: nowrap;
  font-family: var(--typography-v3-h3-comp-font-family);
  font-size: var(--typography-v3-h3-comp-font-size);
  line-height: var(--typography-v3-h3-comp-line-height);
  font-weight: var(--typography-v3-h3-comp-font-weight);
  color: var(--color-text-primary);
}

.priceWithoutDiscounts {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.priceWithoutDiscountsText {
  color: var(--color-text-tertiary);
  font-family: var(--typography-v3-c1-medium-comp-font-family);
  font-size: var(--typography-v3-c1-medium-comp-font-size);
  line-height: var(--typography-v3-c1-medium-comp-line-height);
  font-weight: var(--typography-v3-c1-medium-comp-font-weight);
  white-space: nowrap;
}

.infoPaymentDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.infoPaymentDetailsInfo {
  text-align: left;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}

.infoPaymentDetailsDate {
  text-align: left;
  color: var(--color-text-secondary);
  font-family: var(--typography-v3-c1-regular-comp-font-family);
  font-size: var(--typography-v3-c1-regular-comp-font-size);
  line-height: var(--typography-v3-c1-regular-comp-line-height);
  font-weight: var(--typography-v3-c1-regular-comp-font-weight);
}
