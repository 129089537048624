.blockSlider {
  width: 100%;
  height: 496px;
}
.swiper {
  position: relative;
  height: 100%;
  width: 100%;
}
.swiperSlide {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}
.swiperSlideContent {
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  gap: 40px;
}

.swiperSlideContentTitle {
  min-height: 88px;
  color: var(--color-text-inverted);
  text-align: left;
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
}

.contentList {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contentListItem {
  display: grid;
  grid-template-columns: 24px auto;
  gap: 16px;
}

.contentListItemText {
  text-align: left;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-p2-regular-text-font-family);
  font-size: var(--typography-v3-p2-regular-text-font-size);
  line-height: var(--typography-v3-p2-regular-text-line-height);
  font-weight: var(--typography-v3-p2-regular-text-font-weight);
}

@media only screen and (max-width: 1279.95px) {
  .swiperSlideContent {
    padding-top: 64px;
    gap: 20px;
  }

  .swiperSlideContentTitle {
    min-height: 132px;
  }

  .contentList {
    max-width: 725px;
  }
}
@media only screen and (max-width: 959.95px) {
  .swiperSlideContent {
    padding-top: 40px;
  }

  .swiperSlideContentTitle {
    min-height: 120px;
    font-family: var(--typography-v3-h1-wide-font-family);
    font-size: var(--typography-v3-h1-wide-font-size);
    line-height: var(--typography-v3-h1-wide-line-height);
    font-weight: var(--typography-v3-h1-wide-font-weight);
  }

  .contentList {
    max-width: 545px;
  }

  .contentListItem {
    grid-template-columns: 20px auto;
  }

  .contentListItemText {
    font-family: var(--typography-v3-p3-regular-text-font-family);
    font-size: var(--typography-v3-p3-regular-text-font-size);
    line-height: var(--typography-v3-p3-regular-text-line-height);
    font-weight: var(--typography-v3-p3-regular-text-font-weight);
  }
}

@media only screen and (max-width: 767.95px) {
  .blockSlider {
    height: 640px;
  }

  .swiperSlideContent {
    padding-top: 32px;
    gap: 10px;
  }

  .swiperSlideContentTitle {
    min-height: 200px;
  }

  .contentListItemText {
    font-family: var(--typography-v3-p4-regular-text-font-family);
    font-size: var(--typography-v3-p4-regular-text-font-size);
    line-height: var(--typography-v3-p4-regular-text-line-height);
    font-weight: var(--typography-v3-p4-regular-text-font-weight);
  }

  .break {
    display: none;
  }
}
