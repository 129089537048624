.blockLandingContactUs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px 0px 0px 0px;
}
.card {
  width: 100%;
  height: 228px;
  display: grid;
  grid-template-columns: minmax(200px, 438px) minmax(204px, 1fr) 230px;
  gap: 102px;
  padding: 64px 76px;
}
.cardHeader {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cardHeaderText {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-family: var(--typography-v3-h2-wide-font-family);
  font-size: var(--typography-v3-h2-wide-font-size);
  line-height: var(--typography-v3-h2-wide-line-height);
  font-weight: var(--typography-v3-h2-wide-font-weight);
  color: var(--color-text-primary);
}

.cardHeaderDesc {
  text-align: left;
  color: var(--color-text-secondary);
  font-family: var(--typography-v3-p2-regular-text-font-family);
  font-size: var(--typography-v3-p2-regular-text-font-size);
  line-height: var(--typography-v3-p2-regular-text-line-height);
  font-weight: var(--typography-v3-p2-regular-text-font-weight);
}

.cardContacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardContactsItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cardContactsItemsLinkPhone {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}

.cardContactsItemsLinkEmail {
  text-align: left;
  color: var(--color-text-primary-link);
  font-family: var(--typography-v3-p2-regular-text-font-family);
  font-size: var(--typography-v3-p2-regular-text-font-size);
  line-height: var(--typography-v3-p2-regular-text-line-height);
  font-weight: var(--typography-v3-p2-regular-text-font-weight);
}
.cardButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1439.95px) {
  .card {
    gap: 62px;
    padding: 64px 68px;
  }
}

@media only screen and (max-width: 1279.95px) {
  .card {
    height: auto;
    grid-template-columns: 1fr 230px;
    grid-template-rows: minmax(max-content, 72px) 60px;
    gap: 32px 92px;
    padding: 44px 76px 44px 44px;
  }
  .cardHeader {
    gap: 8px;
  }
  .cardButton {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
}

@media only screen and (max-width: 959.95px) {
  .card {
    grid-template-columns: 1fr 179px;
    grid-template-rows: minmax(max-content, 64px) 52px;
    gap: 32px 61px;
    padding: 32px 60px 32px 28px;
  }

  .cardHeader {
    gap: 4px;
  }

  .cardHeaderDesc {
    font-family: var(--typography-v3-p3-regular-comp-font-family);
    font-size: var(--typography-v3-p3-regular-comp-font-size);
    line-height: var(--typography-v3-p3-regular-comp-line-height);
    font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  }

  .cardContactsItemsLinkEmail {
    font-family: var(--typography-v3-p3-regular-comp-font-family);
    font-size: var(--typography-v3-p3-regular-comp-font-size);
    line-height: var(--typography-v3-p3-regular-comp-line-height);
    font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  }

  .cardContactsItems {
    gap: 4px;
  }
}

@media only screen and (max-width: 767.95px) {
  .card {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    gap: 32px;
    padding: 24px;
  }

  .cardHeader {
    gap: 8px;
  }

  .cardButton {
    grid-column: auto;
    grid-row: auto;
  }
}
