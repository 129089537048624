.blockSlider {
  width: 100%;
  height: 496px;
}
.swiper {
  position: relative;
  height: 100%;
  width: 100%;
}
.swiperSlide {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.swiperSlideContent {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px 88px 56px 88px;
}

.swiperSlideContentIntro {
  text-align: left;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}

.swiperSlideContentTitle {
  text-align: left;
  color: var(--color-text-inverted);
  margin-top: 64px;
  max-width: 630px;
  font-family: var(--typography-v3-h2-wide-font-family);
  font-size: var(--typography-v3-h2-wide-font-size);
  line-height: var(--typography-v3-h2-wide-line-height);
  font-weight: var(--typography-v3-h2-wide-font-weight);
}

.contentImg {
  position: absolute;
}
.contentImg_agronav {
  top: 27px;
  left: 714px;
}
.contentImg_block {
  top: 8px;
  left: 750px;
}
.contentImg_autopilot {
  top: 70px;
  left: 738px;
}
.contentImg_device {
  top: 24px;
  left: 758px;
}
.contentList {
  margin-top: 20px;
  width: 100%;
  max-width: 630px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.contentListText {
  color: var(--color-text-inverted);
  text-align: left;
  font-family: var(--typography-v3-p2-regular-text-font-family);
  font-size: var(--typography-v3-p2-regular-text-font-size);
  line-height: var(--typography-v3-p2-regular-text-line-height);
  font-weight: var(--typography-v3-p2-regular-text-font-weight);
}

.break_1 {
  display: none;
}

@media only screen and (max-width: 1439.95px) {
  .swiperSlideContent {
    padding: 48px 56px 56px;
  }

  .swiperSlideContentTitle {
    margin-top: 32px;
  }

  .contentImg_agronav {
    left: 631px;
  }
  .contentImg_block {
    left: 710px;
  }
  .contentImg_autopilot {
    left: 668px;
  }
  .contentImg_device {
    left: 688px;
  }
  .contentList {
    margin-top: 24px;
  }
  .title__break {
    display: none;
  }
}

@media only screen and (max-width: 1279.95px) {
  .swiperSlideContent {
    padding: 40px;
  }

  .swiperSlideContentTitle {
    margin-top: 24px;
    max-width: 468px;
    min-height: 108px;
  }

  .contentImg_agronav {
    width: 306px;
    top: 48px;
    left: 533px;
  }
  .contentImg_block {
    width: 353px;
    top: 39px;
    left: 540px;
  }
  .contentImg_autopilot {
    width: 462px;
    top: 110px;
    left: 478px;
  }
  .contentImg_device {
    width: 380px;
    top: 64px;
    left: 528px;
  }
  .contentList {
    margin-top: 12px;
    max-width: 468px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 959.95px) {
  .swiperSlideContent {
    padding: 40px 40px 56px;
  }
  .contentImg_agronav {
    top: 28px;
    left: 423px;
  }
  .contentImg_block {
    left: 430px;
  }
  .contentImg_autopilot {
    width: 405px;
    top: 117px;
    left: 458px;
  }
  .contentImg_device {
    width: 304px;
    top: 68px;
    left: 448px;
  }
  .contentList {
    margin-top: 16px;
    max-width: 428px;
  }
  .title__break {
    display: block;
  }
}

@media only screen and (max-width: 959.95px) {
  .swiperSlideContentTitle {
    max-width: 428px;
  }

  .contentListText {
    font-family: var(--typography-v3-p3-regular-text-font-family);
    font-size: var(--typography-v3-p3-regular-text-font-size);
    line-height: var(--typography-v3-p3-regular-text-line-height);
    font-weight: var(--typography-v3-p3-regular-text-font-weight);
  }
}

@media only screen and (max-width: 767.95px) {
  .blockSlider {
    height: 640px;
  }

  .swiperSlideContent {
    padding: 20px 20px 88px;
    align-items: center;
  }

  .swiperSlideContentIntro {
    font-family: var(--typography-v3-c1-medium-comp-font-family);
    font-size: var(--typography-v3-c1-medium-comp-font-size);
    line-height: var(--typography-v3-c1-medium-comp-line-height);
    font-weight: var(--typography-v3-c1-medium-comp-font-weight);
    max-width: 320px;
  }

  .swiperSlideContentTitle {
    max-width: 320px;
    min-height: 144px;
    margin-top: 8px;
  }

  .contentImg {
    left: 50%;
    transform: translateX(-50%);
  }
  .contentImg_agronav {
    width: 178px;
    top: 272px;
  }
  .contentImg_block {
    width: 242px;
    top: 247px;
  }
  .contentImg_autopilot {
    width: 304px;
    top: 305px;
  }
  .contentImg_device {
    width: 198px;
    top: 308px;
  }
  .contentList {
    margin-top: 8px;
    max-width: 320px;
  }

  .contentListText {
    font-family: var(--typography-v3-p4-regular-text-font-family);
    font-size: var(--typography-v3-p4-regular-text-font-size);
    line-height: var(--typography-v3-p4-regular-text-line-height);
    font-weight: var(--typography-v3-p4-regular-text-font-weight);
  }

  .break_1 {
    display: block;
  }
}
