.link {
  border-bottom: 1px solid;
  border-bottom-color: transparent;
  transition: border-bottom-color 0.3s ease-out;
  color: var(--color-text-secondary-link);
  height: 100%;
  display: flex;
  align-items: center;
}

.linkSizeS {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}
.linkSizeM {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}

.link:hover {
  border-bottom-color: var(--color-text-secondary-link-hover);
}

.linkLast {
  color: var(--color-text-primary);
  border-bottom: 1px solid;
  border-bottom-color: transparent;
}
