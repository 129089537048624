.discounts {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.discount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.discountPercent {
  display: flex;
}

.discountType {
  text-align: left;

  font-family: var(--typography-v3-c1-regular-comp-font-family);
  font-size: var(--typography-v3-c1-regular-comp-font-size);
  line-height: var(--typography-v3-c1-regular-comp-line-height);
  font-weight: var(--typography-v3-c1-regular-comp-font-weight);
  color: var(--color-text-primary);
}
