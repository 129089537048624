.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.logoTitle {
  text-align: left;
  font-size: 17px;
  line-height: 17px;
  text-transform: uppercase;
  white-space: nowrap;
  font-family: var(--typography-v3-h4-wide-font-family);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  color: var(--color-text-primary);
}
