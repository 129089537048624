.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 16px;
}

.block {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.blockStation {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex-basis: 320px;
  gap: 32px;
  padding: 24px 20px;
  border: 1px solid;
  border-color: var(--color-background-stroke);
  border-radius: 16px;
}

.blockStationInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.blockStationInfoAddres {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}

.stationData {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stationDataColumn {
  display: flex;
  align-items: center;
}

.stationDataColumnName {
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
  color: var(--color-text-primary);
}

.stationDataColumnInfo {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}

.blockForm {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 36px;
  padding: 24px 20px;
  border: 1px solid;
  border-color: var(--color-background-stroke);
  border-radius: 16px;
}

.form {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 24px;
}

.formTitle {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-h4-comp-font-family);
  font-size: var(--typography-v3-h4-comp-font-size);
  line-height: var(--typography-v3-h4-comp-line-height);
  font-weight: var(--typography-v3-h4-comp-font-weight);
}

.formBlockInputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.input {
  display: flex;
  width: 100%;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.button_bar {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  gap: 12px;
}
