.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.blockInfo {
  text-align: left;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}
.buttonBlock {
  display: inline-block;
}
.button {
  display: flex;
  align-items: center;
  background: transparent;
  color: var(--color-text-primary-link);
  font-size: inherit;
}
.button:hover:not([disabled]) {
  cursor: pointer;
}

.button:focus-visible {
  outline-color: var(--color-text-primary-link);
}

.buttonIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 30px;
}

.tableBlock {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
}

.table {
  display: flex;
  align-items: center;
  width: 296px;
}
.actionBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}
.actionBlockCheckboxText {
  text-align: left;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
  margin-left: 8px;
}
