.blockContainerContent {
  width: var(--container-width);
  min-width: var(--container-width);
  max-width: var(--container-width);
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 48px var(--container-padding);
}
