.blockMobileMenuSupport {
  height: 100%;
  width: auto;
  display: flex;
  gap: 4px;
  align-items: center;
}

.text {
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
  color: var(--color-text-primary);
  text-align: left;
}
