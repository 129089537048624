.blockForm {
  display: grid;
  grid-template-columns: 400px 400px;
  column-gap: 14px;
  row-gap: 24px;
  margin-top: 56px;
}
.blockFormItem {
  display: flex;
}
.blockAction {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 140px;
}
