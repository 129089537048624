.info {
  display: flex;
  margin-top: 32px;
}

.infoText {
  text-align: left;
  font-family: var(--typography-v3-p2-regular-comp-font-family);
  font-size: var(--typography-v3-p2-regular-comp-font-size);
  line-height: var(--typography-v3-p2-regular-comp-line-height);
  font-weight: var(--typography-v3-p2-regular-comp-font-weight);
  color: var(--color-text-primary);
}

.block {
  display: flex;
  margin-top: 48px;
}

.blockCards {
  flex-basis: 100%;
  display: flex;
  gap: 24px;
  justify-content: center;
}
