.paymentsCards {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
}
.paymentsEmptyData {
  max-height: 382px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.loader {
  display: flex;
  justify-content: center;
  padding: 30px 0px;
}
