.blockIntro {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px 0 0 0;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 80px 0 64px 0;
}

.infoTitle {
  text-align: left;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-promo2-short-wide-font-family);
  font-size: var(--typography-v3-promo2-short-wide-font-size);
  line-height: var(--typography-v3-promo2-short-wide-line-height);
  font-weight: var(--typography-v3-promo2-short-wide-font-weight);
  text-transform: var(--typography-v3-promo2-short-wide-text-transform);
}

.infoContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.infoContentText {
  text-align: left;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-p2-regular-text-font-family);
  font-size: var(--typography-v3-p2-regular-text-font-size);
  line-height: var(--typography-v3-p2-regular-text-line-height);
  font-weight: var(--typography-v3-p2-regular-text-font-weight);
}

.infoContentLinks {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.infoContentLink {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  border: 1.21px solid;
  border-color: var(--color-constant-greyscale-0);
  border-radius: 35px;

  &:focus-visible {
    border-color: var(--color-text-primary-link);
    border-radius: 35px;
    outline-color: transparent;
  }
}

.infoContentLinkText {
  font-family: var(--typography-v3-h4-wide-font-family);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-inverted);
  text-align: left;
}

@media only screen and (max-width: 767.95px) {
  .info {
    gap: 20px;
    padding: 64px 0 64px 0;
  }

  .infoTitle {
    text-transform: none;
  }

  .infoContentText {
    font-family: var(--typography-v3-p3-regular-text-font-family);
    font-size: var(--typography-v3-p3-regular-text-font-size);
    line-height: var(--typography-v3-p3-regular-text-line-height);
    font-weight: var(--typography-v3-p3-regular-text-font-weight);
    max-width: 500px;
  }

  .infoContentLinks {
    max-width: 350px;
    column-gap: 2px;
  }

  .infoContentLink_1 {
    order: 1;
  }
  .infoContentLink_2 {
    order: 2;
  }
  .infoContentLink_3 {
    order: 6;
  }
  .infoContentLink_4 {
    order: 4;
  }
  .infoContentLink_5 {
    order: 3;
  }
  .infoContentLink_6 {
    order: 5;
  }
  .infoContent__break {
    display: none;
  }
}
