.block {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 48px 0px 82px 0px;
}

.blockInfo {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.blockInfoTitle {
  text-align: left;
  font-family: var(--typography-v3-h2-wide-font-family);
  font-size: var(--typography-v3-h2-wide-font-size);
  line-height: var(--typography-v3-h2-wide-line-height);
  font-weight: var(--typography-v3-h2-wide-font-weight);
  color: var(--color-text-primary);
}
.blockInfoContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.blockInfoContentTitle {
  text-align: left;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-primary);
}

.blockInfoContentList {
  display: flex;
  flex-direction: column;
}

.blockInfoContentList li {
  text-align: left;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-primary);
}

.blockChapter {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.chapterTitle {
  text-align: left;
  font-family: var(--typography-v3-p3-medium-comp-font-family);
  font-size: var(--typography-v3-p3-medium-comp-font-size);
  line-height: var(--typography-v3-p3-medium-comp-line-height);
  font-weight: var(--typography-v3-p3-medium-comp-font-weight);
  color: var(--color-text-primary);
}
.chapterList {
  display: flex;
  flex-direction: column;
}

.chapterList li {
  text-align: left;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-primary);
}
