.blockInfoNumbers {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}

.blockNumbers {
  display: grid;
  grid-template-columns: repeat(auto-fill, 224px);
  column-gap: 32px;
  row-gap: 16px;
}

.blockNumber {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.blockNumberText {
  text-align: left;
  display: flex;
  gap: 8px;
  align-items: flex-end;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-promo2-short-wide-font-family);
  font-size: var(--typography-v3-promo2-short-wide-font-size);
  line-height: var(--typography-v3-promo2-short-wide-line-height);
  font-weight: var(--typography-v3-promo2-short-wide-font-weight);
  text-transform: var(--typography-v3-promo2-short-wide-text-transform);
}

.blockNumberText span {
  text-align: left;
  white-space: nowrap;
  color: inherit;
  padding-bottom: 3px;
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}

.blockNumberInfo {
  font-family: var(--typography-v3-p4-regular-text-font-family);
  font-size: var(--typography-v3-p4-regular-text-font-size);
  line-height: var(--typography-v3-p4-regular-text-line-height);
  font-weight: var(--typography-v3-p4-regular-text-font-weight);
  color: var(--color-text-primary);
  text-align: left;
}

@media only screen and (max-width: 1279.95px) {
  .blockInfoNumbers {
    margin-top: 8px;
  }
  .blockNumbers {
    row-gap: 24px;
  }
}

@media only screen and (max-width: 959.95px) {
  .blockNumbers {
    row-gap: 32px;
  }

  .blockNumberText {
    font-family: var(--typography-v3-h1-wide-font-family);
    font-size: var(--typography-v3-h1-wide-font-size);
    line-height: var(--typography-v3-h1-wide-line-height);
    font-weight: var(--typography-v3-h1-wide-font-weight);
  }

  .blockNumberText span {
    font-family: var(--typography-v3-p3-medium-text-font-family);
    font-size: var(--typography-v3-p3-medium-text-font-size);
    line-height: var(--typography-v3-p3-medium-text-line-height);
    font-weight: var(--typography-v3-p3-medium-text-font-weight);
  }
}

@media only screen and (max-width: 767.95px) {
  .blockInfoNumbers {
    margin-top: 0px;
    gap: 20px;
  }
  .blockNumbers {
    grid-template-columns: repeat(2, 154px);
    column-gap: 12px;
  }
  .blockNumberBreak {
    display: none;
  }
}
