.blockMTSInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blockLabelInput {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.blockInputButton {
  position: absolute;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid;
  border-color: var(--color-background-stroke);
  background-color: var(--color-background-secondary);
  border-radius: 8px;
  transition: 0.2s all ease-out;

  &:hover {
    border-color: var(--color-control-secondary-active);
  }
}
.inputContainerError {
  border-color: var(--color-accent-negative);

  &:hover {
    border-color: var(--color-accent-negative);
  }
}
.inputContainerFocused {
  border-color: var(--color-accent-active);

  &:hover {
    border-color: var(--color-accent-active);
  }
}
.inputContainerDisabled {
  background-color: var(--color-background-secondary);

  &:hover {
    border-color: var(--color-background-stroke);
  }

  &:focus-within {
    border-color: var(--color-background-stroke);
  }
}
.inputContainerSizeS {
  height: 32px;
  border-radius: 6px;
}
.inputContainerSizeM {
  height: 44px;
}
.inputContainerSizeL {
  height: 52px;
}
.inputContainerSizeXL {
  height: 72px;
  border-radius: 10px;
}

.input {
  width: 100%;
  height: 100%;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  border: none;
  background-color: transparent;
  color: var(--color-text-primary);

  &::placeholder {
    color: var(--color-text-secondary);
  }

  &:disabled {
    background-color: transparent;
    color: var(--color-text-secondary);
  }
}

.inputSizeS {
  padding: 6px 12px;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}
.inputSizeM {
  padding: 10px 12px;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}
.inputSizeL {
  padding: 14px 12px;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}
.inputSizeXL {
  height: 100%;
  padding: 34px 8px 14px 12px;
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}
.inputSizeXLHide {
  opacity: 0;
}

.inputLabel {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
  transition: 0.2s all ease-out;
}
.inputLabelHide {
  margin-bottom: 0px;
}
.inputLabelFocusedSizeXL {
  position: absolute;
  top: 14px;
  left: 12px;
}
.inputLabelUnfocusedSizeXL {
  position: absolute;
  top: 24px;
  left: 12px;
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

.inputPasswordHiddenSizeS {
  font-family: Verdana;
  letter-spacing: 1.12px;
  font-weight: 600;
}
.inputPasswordHiddenSizeM {
  font-family: Verdana;
  letter-spacing: 1.4px;
  font-weight: 600;
}
.inputPasswordHiddenSizeL {
  font-family: Verdana;
  letter-spacing: 1.4px;
  font-weight: 600;
}
.inputPasswordHiddenSizeXL {
  font-family: Verdana;
  letter-spacing: 1.4px;
  font-weight: 600;
}

.inputPasswordVisibleSizeS {
  letter-spacing: 4px;
}
.inputPasswordVisibleSizeM {
  letter-spacing: 6px;
}
.inputPasswordVisibleSizeL {
  letter-spacing: 6px;
}
.inputPasswordVisibleSizeXL {
  letter-spacing: 6px;
}
.inputPasswordEmpty {
  letter-spacing: 0;
}
.inputMessage {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(20px);
}
