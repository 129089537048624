.block {
  width: 100%;
  height: 100%;
  min-height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.contentInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contentInfoTitle {
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  color: var(--color-text-primary);
  text-align: center;
}

.contentInfoText {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-secondary);
  text-align: center;
}
.contentInfoErrorText {
  font-family: var(--typography-v3-h3-wide-font-family);
  font-size: var(--typography-v3-h3-wide-font-size);
  line-height: var(--typography-v3-h3-wide-line-height);
  font-weight: var(--typography-v3-h3-wide-font-weight);
  color: var(--color-text-primary);
  text-align: center;
}

.contentText {
  margin-top: 10px;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
  text-align: center;
}
.contentBtn {
  display: flex;
}
