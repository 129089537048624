.blockFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-background-secondary);
}

.content {
  width: 100%;
  height: var(--footer-personal-area-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentText {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-secondary);
}

@media only screen and (max-width: 1439.95px) {
  .contentText {
    font-family: var(--typography-v3-p4-regular-comp-font-family);
    font-size: var(--typography-v3-p4-regular-comp-font-size);
    line-height: var(--typography-v3-p4-regular-comp-line-height);
    font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  }
}
