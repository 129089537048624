.blockForm {
  display: grid;
  grid-template-columns: 400px 400px;
  gap: 32px;
}
.blockFormItem {
  display: flex;
}
.blockAction {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
