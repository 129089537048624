.blockMTSPagination {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.btn {
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 6px;
  border: 2px solid;
  border-color: transparent;
  transition: all 0.3s ease-in;
}

.btn:focus {
  border-color: var(--color-background-hover);
}

.btnBreadcrumbs {
  padding: 0 10px;
}

.btnText {
  text-align: center;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-medium-comp-font-family);
  font-size: var(--typography-v3-p3-medium-comp-font-size);
  line-height: var(--typography-v3-p3-medium-comp-line-height);
  font-weight: var(--typography-v3-p3-medium-comp-font-weight);
}

.btnPage {
  padding: 0 10px;
  background-color: transparent;
  cursor: pointer;
}

.btnPageSelected {
  padding: 0 10px;
  background-color: var(--color-background-primary);
  cursor: pointer;
  box-shadow: var(--shadow-low);
}

.btnNav {
  background: transparent;
  cursor: pointer;
}
