.buttonText {
  display: flex;
  align-items: center;
  gap: 4px;
}
.profileWidget {
  width: 0px;
  height: 0px;
  display: none;
  overflow: hidden;
}
