.container {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: minmax(700px, calc(100vh - var(--header-desktop-height)));
}

.intro {
  display: flex;
  flex-direction: column;
  padding: 109px var(--container-padding) 0 var(--container-padding);
  z-index: 1;
}

.introTitle {
  width: 100%;
  hyphens: none;
  font-family: var(--typography-v3-promo2-short-wide-font-family);
  font-size: var(--typography-v3-promo2-short-wide-font-size);
  line-height: var(--typography-v3-promo2-short-wide-line-height);
  font-weight: var(--typography-v3-promo2-short-wide-font-weight);
  text-transform: var(--typography-v3-promo2-short-wide-text-transform);
}

.introText {
  width: 449px;
  margin-top: 24px;
  display: flex;
  text-align: left;
  font-family: var(--typography-v3-p4-medium-upp-comp-font-family);
  font-size: var(--typography-v3-p4-medium-upp-comp-font-size);
  line-height: var(--typography-v3-p4-medium-upp-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-upp-comp-font-weight);
  text-transform: var(--typography-v3-p4-medium-upp-comp-text-transform);
}

.introBtn {
  width: 292px;
  display: flex;
  margin-top: 48px;
}

.introDesc {
  margin-top: 12px;
  text-align: left;
  font-family: var(--typography-v3-c1-medium-comp-font-family);
  font-size: var(--typography-v3-c1-medium-comp-font-size);
  line-height: var(--typography-v3-c1-medium-comp-line-height);
  font-weight: var(--typography-v3-c1-medium-comp-font-weight);
}

.btnMap {
  position: absolute;
  right: var(--container-padding);
  bottom: 88px;
  z-index: 1;
}

.backgroundMap {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./img/map1552.png");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

@media only screen and (min-height: 900px) {
  .container {
    grid-template-rows: calc(900px - var(--header-desktop-height));
  }
}

@media only screen and (max-width: 1279.95px) {
  .container {
    grid-template-columns: 100vw;
    grid-template-rows: calc(
      100vh - var(--header-desktop-height) - var(--mobile-bar-height)
    );
  }
  .backgroundMap {
    background-image: url("./img/map960.png");
    background-size: cover;
    opacity: 0.7;
  }
}

@media only screen and (max-width: 959.95px) {
  .backgroundMap {
    background-image: url("./img/map768.png");
    opacity: 0.7;
  }
}

@media only screen and (max-width: 959.95px) and (orientation: landscape) {
  .backgroundMap {
    background-size: cover;
  }
}
@media only screen and (max-width: 959.95px) and (orientation: portrait) {
  .backgroundMap {
    background-size: contain;
  }
}

@media only screen and (max-width: 767.95px) {
  .intro {
    padding-top: 89px;
  }

  .introTitle {
    width: auto;
    max-width: 100%;
    font-family: var(--typography-v3-h2-wide-font-family);
    font-size: var(--typography-v3-h2-wide-font-size);
    line-height: var(--typography-v3-h2-wide-line-height);
    font-weight: var(--typography-v3-h2-wide-font-weight);
    hyphens: manual;
  }

  .btnMap {
    bottom: 10px;
  }

  .backgroundMap {
    background-size: cover;
  }
}

@media only screen and (max-width: 767.95px) and (orientation: landscape) {
  .backgroundMap {
    background-position: center;
  }
}
@media only screen and (max-width: 767.95px) and (orientation: portrait) {
  .backgroundMap {
    height: 60%;
    background-position: bottom;
  }
}

@media only screen and (max-width: 500px) {
  .introBtn {
    width: 100%;
  }
}
