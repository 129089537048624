.blockInputLabel {
  height: 20px;
  display: flex;
}

.label {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-secondary);
  transition: 0.3s all ease-out;
  text-align: left;
}

.labelSizeXL {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-secondary);
  transition: 0.3s all ease-out;
  text-align: left;
}

.labelSizeXLFocused {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}

.labelError,
.labelSizeXLError {
  color: var(--color-text-negative);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chip {
  display: flex;
  align-items: center;
  background-color: var(--color-background-secondary);
  padding: 0px 4px;
  margin-left: 4px;
  border-radius: 4px;
}

.chipText {
  font-family: var(--typography-v3-c1-medium-comp-font-family);
  font-size: var(--typography-v3-c1-medium-comp-font-size);
  line-height: var(--typography-v3-c1-medium-comp-line-height);
  font-weight: var(--typography-v3-c1-medium-comp-font-weight);
  color: var(--color-text-secondary);
}
