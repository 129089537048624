.block {
  display: flex;
  margin-top: 16px;
  gap: 20px;
}

.blockLabel {
  text-align: left;

  font-family: var(--typography-v3-h2-wide-font-family);
  font-size: var(--typography-v3-h2-wide-font-size);
  line-height: var(--typography-v3-h2-wide-line-height);
  font-weight: var(--typography-v3-h2-wide-font-weight);
  color: var(--color-text-primary);
}
