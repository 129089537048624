:root {
  --panel-shift-top: 12px;
  --panel-shift-left: 12px;
  --panel-shift-bottom: 12px;
}

.main {
  position: relative;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  display: block;
  overflow: hidden;
}

.panel {
  position: absolute;
  top: calc(var(--panel-shift-top) + var(--header-desktop-height));
  left: var(--panel-shift-left);
  z-index: 1000;
  max-height: calc(
    100vh - var(--header-desktop-height) - var(--panel-shift-top) -
      var(--panel-shift-bottom) - 48px
  );
  overflow-y: auto;
  padding: 24px;
  background-color: var(--color-background-primary);
  box-shadow: var(--shadow-low);
  border-radius: 8px;
}

.buttonPanelMobile {
  position: absolute;
  top: calc(var(--panel-shift-top) + var(--header-desktop-height));
  left: 12px;
  display: block;
  z-index: 1000;
}

.map {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mapContainer {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 1279.95px) {
  .panel {
    max-height: calc(
      100vh - var(--header-desktop-height) - var(--mobile-bar-height) -
        var(--panel-shift-top) - var(--panel-shift-bottom) - 48px
    );
  }
}
