.blockProblems {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 120px 0px 0px 0px;
}

.title {
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
  text-align: left;
  color: var(--color-text-primary);
}

.list {
  display: grid;
  grid-template-columns: repeat(4, minmax(196px, 292px));
  justify-content: space-between;
  margin-top: 64px;
  column-gap: 32px;
  row-gap: 56px;
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.listItemTitle {
  text-align: center;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}

@media only screen and (max-width: 959.95px) {
  .list {
    grid-template: repeat(2, auto) / repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767.95px) {
  .list {
    grid-template: repeat(4, auto) / 320px;
    margin-top: 56px;
    justify-content: center;
  }
}
