.blockCard {
  min-height: 580px;
  display: grid;
  grid-template-columns: minmax(33.33%, 400px);
}

.card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  background-color: var(--color-background-primary);
  box-shadow: var(--shadow-low);
  border-radius: 16px;
  transition: box-shadow 0.3s ease-out;
}
.card:hover {
  box-shadow: var(--shadow-middle);
}

.cardBlock {
  display: flex;
  flex-direction: column;
}

.cardSlider {
  height: 56px;
  padding: 0px 20px;
  margin-top: 4px;
}

.cardBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.tariff {
  min-height: 82px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.tariffCategory {
  flex-basis: 100%;
  display: flex;
}

.tariffCalculations {
  min-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
}

.tariffInfo {
  display: flex;
  margin-top: 24px;
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  color: var(--color-text-primary);
  text-align: left;
}

.tariffDiscounts {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}

.tariffPrices {
  display: flex;
  gap: 10px;
}

.tariffPrice {
  font-family: var(--typography-v3-p3-regular-text-font-family);
  font-size: var(--typography-v3-p3-regular-text-font-size);
  line-height: var(--typography-v3-p3-regular-text-line-height);
  font-weight: var(--typography-v3-p3-regular-text-font-weight);
  color: var(--color-text-primary);
  text-align: left;
}
.tariffPriceNumber {
  font-family: var(--typography-v3-h3-wide-font-family);
  font-size: var(--typography-v3-h3-wide-font-size);
  line-height: var(--typography-v3-h3-wide-line-height);
  font-weight: var(--typography-v3-h3-wide-font-weight);
  color: inherit;
}

.tariffPriceWithoutDiscount {
  display: flex;
  align-items: center;
}

.tariffPriceWithoutDiscountNumber {
  font-family: var(--typography-v3-p4-medium-upp-comp-font-family);
  font-size: var(--typography-v3-p4-medium-upp-comp-font-size);
  line-height: var(--typography-v3-p4-medium-upp-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-upp-comp-font-weight);
  text-transform: var(--typography-v3-p4-medium-upp-comp-text-transform);
  color: var(--color-text-tertiary);
  white-space: nowrap;
}

.tariffPriceWithoutDiscountCurrency {
  font-family: var(--typography-v3-p4-regular-text-font-family);
  font-size: var(--typography-v3-p4-regular-text-font-size);
  line-height: var(--typography-v3-p4-regular-text-line-height);
  font-weight: var(--typography-v3-p4-regular-text-font-weight);
  color: var(--color-text-tertiary);
}

@media only screen and (max-width: 1439.95px) {
  .card {
    padding: 40px 24px;
  }
}
@media only screen and (max-width: 1279.95px) {
  .blockCard {
    min-height: auto;
    display: flex;
    flex: 1;
  }

  .card {
    padding: 40px 44px;
  }
}

@media only screen and (max-width: 959.95px) {
  .card {
    padding: 40px 28px;
  }

  .tariff {
    margin-top: 24px;
  }

  .tariffCalculations {
    margin-top: 24px;
  }

  .tariffPrices {
    flex-direction: column;
    gap: 0px;
    height: 42px;
  }

  .cardBtn {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 767.95px) {
  .card {
    padding: 24px;
  }
}
