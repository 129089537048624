.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cardGroup {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid;
  border-bottom-color: var(--color-background-stroke);
  margin-right: 46px;
}

.cardGroupHeader {
  display: flex;
  gap: 20px;
}

.cardGroupHeaderTitle {
  text-align: left;
  font-family: var(--typography-v3-h3-comp-font-family);
  font-size: var(--typography-v3-h3-comp-font-size);
  line-height: var(--typography-v3-h3-comp-line-height);
  font-weight: var(--typography-v3-h3-comp-font-weight);
  color: var(--color-text-primary);
}

.cardGroupInputs {
  margin-top: 24px;
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 52px;
  column-gap: 40px;
  padding-bottom: 24px;
}

.cardGroupInput {
  max-width: 100%;
}

.cardDiscount {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
}

.cardDiscountNumber {
  text-align: left;
  font-family: var(--typography-v3-h4-comp-font-family);
  font-size: var(--typography-v3-h4-comp-font-size);
  line-height: var(--typography-v3-h4-comp-line-height);
  font-weight: var(--typography-v3-h4-comp-font-weight);
  color: var(--color-text-tertiary);
  white-space: nowrap;
}

.cardDiscountDesc {
  text-align: left;
  font-family: var(--typography-v3-c1-regular-comp-font-family);
  font-size: var(--typography-v3-c1-regular-comp-font-size);
  line-height: var(--typography-v3-c1-regular-comp-line-height);
  font-weight: var(--typography-v3-c1-regular-comp-font-weight);
  color: var(--color-text-secondary);
}

.addButton {
  display: flex;
  margin: 16px 0 0 0;
}
