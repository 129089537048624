.blockApplication {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 136px;
  gap: 72px;
}
.blockApplicationInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}

.blockApplicationInfoTitle {
  text-align: left;
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
  color: var(--color-text-primary);
}

.blockApplicationCard {
  width: 100%;
  gap: 32px;
  display: grid;
  grid-template-areas:
    "agriculture geodesy equipment"
    "agriculture deformation aircraft"
    "storage navigation aircraft";
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 280px 280px 280px;
  margin-top: 56px;
}
.cardLink {
  transition: 0.3s;
  outline: none;

  &:focus {
    border-radius: 20px;
    box-shadow: var(--shadow-middle);
    transform: translateY(-5px);
  }
  &:hover {
    cursor: pointer;
    box-shadow: var(--shadow-middle);
    transform: translateY(-5px);
  }
}
.cardAgriculture {
  position: relative;
  overflow: hidden;
  grid-area: agriculture;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 24px 56px 24px;
  background-color: var(--color-constant-greyscale-900);
  border-radius: 20px;
  z-index: 80;
}
.cardGeodesy {
  grid-area: geodesy;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--color-constant-greyscale-900);
  border-radius: 20px;
}
.cardEquipment {
  grid-area: equipment;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--color-constant-greyscale-900);
  border-radius: 20px;
}
.cardDeformation {
  grid-area: deformation;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--color-constant-greyscale-900);
  border-radius: 20px;
}
.cardAircraft {
  position: relative;
  grid-area: aircraft;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 24px 56px 24px;
  background-color: var(--color-constant-greyscale-900);
  border-radius: 20px;
  overflow: hidden;
  z-index: 80;
}
.cardNavigation {
  grid-area: navigation;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--color-constant-greyscale-900);
  border-radius: 20px;
}
.cardStorage {
  grid-area: storage;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--color-constant-greyscale-900);
  border-radius: 20px;
}
.cardBackground {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: -80%;
  right: 0;
}
.cardBackgroundVideo {
  height: 100%;
  object-fit: cover;
  margin-top: -50px;
}
.cardShadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    358.71deg,
    rgba(0, 0, 0, 0.855) 34.9%,
    rgba(60, 64, 80, 0.38) 67.79%
  );
  z-index: 50;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 100;
}

.cardContentTitle {
  text-align: left;
  color: var(--color-text-inverted);
  text-transform: uppercase;
  font-family: var(--typography-v3-h3-wide-font-family);
  font-size: var(--typography-v3-h3-wide-font-size);
  line-height: var(--typography-v3-h3-wide-line-height);
  font-weight: var(--typography-v3-h3-wide-font-weight);
}

.cardContentTitleExtend {
  hyphens: none;
}

.cardList {
  min-height: 144px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cardListItem {
  width: 100%;
  display: flex;
}

.cardListItemText {
  text-align: left;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-p3-regular-text-font-family);
  font-size: var(--typography-v3-p3-regular-text-font-size);
  line-height: var(--typography-v3-p3-regular-text-line-height);
  font-weight: var(--typography-v3-p3-regular-text-font-weight);
}

.cardListItemTextExtend {
  white-space: nowrap;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
}

@media only screen and (min-width: 960px) and (max-width: 1279.95px) {
  .blockApplicationCard {
    grid-template-rows: 352px 352px 352px;
  }

  .cardContentTitleExtend {
    hyphens: manual;
  }

  .cardAgriculture {
    padding: 20px 20px 88px 20px;
  }
  .cardGeodesy {
    padding: 20px;
  }
  .cardEquipment {
    padding: 20px;
  }
  .cardDeformation {
    padding: 20px;
  }
  .cardAircraft {
    padding: 20px 20px 88px 20px;
  }
  .cardNavigation {
    padding: 20px;
  }
  .cardStorage {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 959.95px) {
  .blockApplicationCard {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 332px 332px 332px 332px 332px;
    grid-template-areas:
      "agriculture  storage"
      "agriculture  geodesy"
      "deformation  deformation"
      "navigation aircraft"
      "equipment  aircraft";
  }
  .cardAgriculture {
    padding: 24px 24px 60px 24px;
  }
  .cardAircraft {
    padding: 24px 24px 84px 24px;
  }
}

@media only screen and (max-width: 767.95px) {
  .blockApplicationCard {
    grid-template-columns: 1fr;
    grid-template-rows: 592px 356px 356px 356px 356px 356px 592px;
    grid-template-areas:
      "agriculture"
      "storage"
      "geodesy"
      "deformation"
      "navigation"
      "equipment"
      "aircraft";
  }
  .cardBackground {
    left: -40%;
  }
}

@media only screen and (max-width: 500px) {
  .cardBackground {
    left: -80%;
  }
}
