.blockIntro {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px 0 0 0;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 80px 0 64px 0;
}

.infoTitle {
  text-align: left;
  color: var(--color-text-inverted);

  font-family: var(--typography-v3-promo2-short-wide-font-family);
  font-size: var(--typography-v3-promo2-short-wide-font-size);
  line-height: var(--typography-v3-promo2-short-wide-line-height);
  font-weight: var(--typography-v3-promo2-short-wide-font-weight);
  text-transform: var(--typography-v3-promo2-short-wide-text-transform);
}

.infoContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.infoContentTitle {
  text-align: left;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-p2-regular-text-font-family);
  font-size: var(--typography-v3-p2-regular-text-font-size);
  line-height: var(--typography-v3-p2-regular-text-line-height);
  font-weight: var(--typography-v3-p2-regular-text-font-weight);
}

.infoContentLinks {
  max-width: 480px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.infoContentLink {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 23px;
  border: 1.21px solid;
  border-color: var(--color-constant-greyscale-0);
  border-radius: 35px;

  &:focus-visible {
    border-color: var(--color-text-primary-link);
    border-radius: 35px;
    outline-color: transparent;
  }
}

.infoContentLinkText {
  text-align: left;
  color: var(--color-text-inverted);
  font-family: var(--typography-v3-h4-wide-font-family);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  font-size: 14px;
  line-height: 20px;
}

@media only screen and (max-width: 767.95px) {
  .info {
    padding: 64px 0 64px 0;
  }

  .infoTitle {
    text-transform: none;
  }

  .infoContent {
    gap: 40px;
  }

  .infoContentTitle {
    font-family: var(--typography-v3-p3-regular-text-font-family);
    font-size: var(--typography-v3-p3-regular-text-font-size);
    line-height: var(--typography-v3-p3-regular-text-line-height);
    font-weight: var(--typography-v3-p3-regular-text-font-weight);
  }

  .infoContentLink {
    padding: 0 15px;
  }
  .infoContentBreak {
    display: none;
  }
  .infoContentSpan {
    display: inline-block;
  }
}
