.blockQuestions {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 136px;
}

.title {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
  text-align: left;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 56px;
}

.listQuestion {
  font-family: var(--typography-v3-h4-comp-font-family);
  font-size: var(--typography-v3-h4-comp-font-size);
  line-height: var(--typography-v3-h4-comp-line-height);
  font-weight: var(--typography-v3-h4-comp-font-weight);
  color: var(--color-text-primary);
  text-align: left;
  padding-right: 15px;
}

.listItem {
  display: flex;
  flex-direction: column;
}

.listItemFact {
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  color: var(--color-text-primary);
  text-align: left;
}

.itemSubtext {
  display: block;
}

@media only screen and (max-width: 959.95px) {
  .itemSubtext {
    display: inline;
  }
}

@media only screen and (max-width: 767.95px) {
  .titleSubtext {
    white-space: nowrap;
  }

  .listQuestion {
    font-family: var(--typography-v3-p3-medium-comp-font-family);
    font-size: var(--typography-v3-p3-medium-comp-font-size);
    line-height: var(--typography-v3-p3-medium-comp-line-height);
    font-weight: var(--typography-v3-p3-medium-comp-font-weight);
    padding-right: 12px;
  }
}
