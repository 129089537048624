.blockMessage {
  width: 100%;
  max-width: 447px;
  display: flex;
  justify-content: center;
}

.blockMessageText {
  text-align: center;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}

.blockMessageTextLink {
  display: inline-block;
  background: transparent;
  color: var(--color-text-primary-link);
  font-size: inherit;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline-color: var(--color-text-primary-link);
  }
}
