.headerTitle {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-bold-comp-font-family);
  font-size: var(--typography-v3-p3-bold-comp-font-size);
  line-height: var(--typography-v3-p3-bold-comp-line-height);
  font-weight: var(--typography-v3-p3-bold-comp-font-weight);
}

.infoStatusText,
.infoLocationText,
.listItemText {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}

.listItemLabel {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
}
