.btn {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  transition: 0.3s transform ease-out;
  outline-color: transparent;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  &:focus-visible {
    outline-color: transparent;
    transform: scale(1.05);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: blur(10px);
    transform: scale(1.05);
    background: linear-gradient(
      90deg,
      rgba(136, 79, 173, 0.4),
      rgba(97, 195, 197, 0.4)
    );
    background-size: 200% 200%;
    animation: animationGlow 3s ease-in-out infinite;
    border-radius: 50%;
  }
}

.btnContent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-constant-greyscale-0);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid #eaeaea;
  border-radius: 50%;
}

.btnLabel {
  text-align: center;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-c1-medium-comp-font-family);
  font-size: var(--typography-v3-c1-medium-comp-font-size);
  line-height: var(--typography-v3-c1-medium-comp-line-height);
  font-weight: var(--typography-v3-c1-medium-comp-font-weight);
}

@keyframes animationGlow {
  .0% {
    background-position: 0% 200%;
    transform: rotate(0deg) scale(1.05);
  }
  .25% {
    background-position: 50% 160%;
    transform: rotate(300deg) scale(1.1);
  }
  .75% {
    background-position: 100% 200%;
    transform: rotate(160deg) scale(1.03);
  }
  .100% {
    background-position: 0% 100%;
    transform: rotate(0deg) scale(1.05);
  }
}
