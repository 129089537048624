.blockEffects {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px 0 0 0;
}

.title {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
}

.description {
  text-align: left;
  color: var(--color-text-primary);
  margin-top: 64px;
  font-family: var(--typography-v3-p2-regular-text-font-family);
  font-size: var(--typography-v3-p2-regular-text-font-size);
  line-height: var(--typography-v3-p2-regular-text-line-height);
  font-weight: var(--typography-v3-p2-regular-text-font-weight);
}

.info {
  margin-top: 47px;
  display: grid;
  grid-template-columns: minmax(480px, 508px) minmax(668px, 1fr);
  gap: 56px;
}

.infoData {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1439.95px) {
  .info {
    gap: 20px;
  }
}

@media only screen and (max-width: 1279.95px) {
  .info {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
  .infoData {
    display: grid;
    grid-template-columns: minmax(388px, 1fr) 224px;
    gap: 76px;
    padding-right: 48px;
  }
}

@media only screen and (max-width: 959.95px) {
  .infoData {
    grid-template-columns: minmax(388px, 1fr) 240px;
    gap: 60px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 767.95px) {
  .description {
    margin-top: 56px;
  }

  .info {
    gap: 40px;
    margin-top: 44px;
  }

  .infoData {
    display: flex;
    flex-direction: column;
    gap: 56px;
  }
}
