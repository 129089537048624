.pagination {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  gap: 16px;
}
.paginationItem {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cell {
  height: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: 6px;
  border: 2px solid transparent;
}

.cell:focus {
  border-color: var(--color-background-hover);
}

.cellText {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-medium-comp-font-family);
  font-size: var(--typography-v3-p3-medium-comp-font-size);
  line-height: var(--typography-v3-p3-medium-comp-line-height);
  font-weight: var(--typography-v3-p3-medium-comp-font-weight);
}

.pageBreadcrumbs {
  padding: 0 10px;
}
.pageBreadcrumbsText {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-medium-comp-font-family);
  font-size: var(--typography-v3-p3-medium-comp-font-size);
  line-height: var(--typography-v3-p3-medium-comp-line-height);
  font-weight: var(--typography-v3-p3-medium-comp-font-weight);
}

.btnPage {
  padding: 0 10px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.btnPageSelected {
  padding: 0 10px;
  background-color: var(--color-background-primary);
  cursor: pointer;
  box-shadow: var(--shadow-low);
}
.btnNav {
  background: transparent;
  cursor: pointer;
}
