.badge {
  display: flex;
  justify-content: center;
  align-items: center;
}
.badgeSizeXS {
  height: 16px;
  border-radius: 4px;
  padding: 0px 4px;
}
.badgeSizeS {
  height: 20px;
  border-radius: 4px;
  padding: 0px 4px;
}
.badgeSizeM {
  height: 24px;
  border-radius: 6px;
  padding: 0px 6px;
}
.badgeSizeL {
  height: 32px;
  border-radius: 8px;
  padding: 0px 8px;
}
.badgeVariantGrey {
  background-color: var(--color-background-secondary);
}
.badgeVariantCustomColor {
  background-color: var(--color-accent-active);
}

.text {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
}

.textNoWrap {
  white-space: nowrap;
}

.textGrey {
  color: var(--color-text-primary);
}

.textCustomColor {
  color: var(--color-text-inverted);
}

.textSizeXS,
.textSizeS {
  font-family: var(--typography-v3-c1-medium-comp-font-family);
  font-size: var(--typography-v3-c1-medium-comp-font-size);
  line-height: var(--typography-v3-c1-medium-comp-line-height);
  font-weight: var(--typography-v3-c1-medium-comp-font-weight);
}

.textSizeM {
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
}

.textSizeL {
  font-family: var(--typography-v3-p3-medium-comp-font-family);
  font-size: var(--typography-v3-p3-medium-comp-font-size);
  line-height: var(--typography-v3-p3-medium-comp-line-height);
  font-weight: var(--typography-v3-p3-medium-comp-font-weight);
}
