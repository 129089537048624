.block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.text {
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}

.link {
  margin-top: 24px;
  color: var(--color-text-primary-link);
}

.link:hover {
  cursor: pointer;
}
