.blockCases {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 120px 0 0 0;
}

.title {
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
  text-align: left;
  color: var(--color-text-primary);
}

.tabs {
  display: flex;
  margin-top: 64px;
}

.contentWrapper {
  margin-top: 64px;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(300px, 616px) 540px;
  gap: 32px;
}

.contentInfo {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.contentInfoItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contentInfoItemTitle {
  text-align: left;
  font-family: var(--typography-v3-h2-wide-font-family);
  font-size: var(--typography-v3-h2-wide-font-size);
  line-height: var(--typography-v3-h2-wide-line-height);
  font-weight: var(--typography-v3-h2-wide-font-weight);
  color: var(--color-text-primary);
}

.contentInfoItemList {
  max-width: 508px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contentInfoItemListText {
  text-align: left;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
}

.contentImg {
  padding-top: 56px;

  & img {
    width: 100%;
    height: auto;
  }
}
.tabsMobile {
  margin-top: 64px;
}

@media only screen and (max-width: 1439.95px) {
  .contentWrapper {
    grid-template-columns: minmax(300px, 468px) 500px;
    gap: 132px;
  }
}

@media only screen and (max-width: 1279.95px) {
  .contentWrapper {
    grid-template-columns: 424px minmax(348px, 500px);
    gap: 108px;
  }
}

@media only screen and (max-width: 959.95px) {
  .contentWrapper {
    grid-template-columns: 328px minmax(328px, 348px);
    gap: 32px;
  }

  .contentInfoItemListText {
    font-family: var(--typography-v3-p4-regular-comp-font-family);
    font-size: var(--typography-v3-p4-regular-comp-font-size);
    line-height: var(--typography-v3-p4-regular-comp-line-height);
    font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  }
}

@media only screen and (max-width: 767.95px) {
  .tabs {
    margin-top: 56px;
  }

  .contentWrapper {
    margin-top: 56px;
    grid-template-columns: minmax(320px, 508px);
    gap: 56px;
  }

  .contentImg {
    padding-top: 0px;
    grid-row: 1 / 2;
    max-width: 320px;
  }

  .tabsMobile {
    margin-top: 56px;
  }
}
