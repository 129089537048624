.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.mainBlock {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.mainBlockMessage {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.mainBlockContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mainBlockContentForm {
  display: flex;
  margin-top: 36px;
  gap: 40px;
}
.buttonDeleteBlock {
  display: flex;
  margin-top: 23px;
}
.form {
  width: 100%;
  max-width: 840px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.formBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formBlockTitle {
  text-align: left;
  font-family: var(--typography-v3-h4-comp-font-family);
  font-size: var(--typography-v3-h4-comp-font-size);
  line-height: var(--typography-v3-h4-comp-line-height);
  font-weight: var(--typography-v3-h4-comp-font-weight);
  color: var(--color-text-primary);
}

.formBlockInputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 40px;
  row-gap: 32px;
}
.formBlockInput {
  display: flex;
  flex-grow: 1;
}
.stepperBlock {
  display: flex;
  flex-grow: 1;
}
.buttonWrapper {
  margin-top: 44px;
  display: flex;
}
.footerBlock {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 40px 0 0 0;
}

.dialogText {
  color: var(--color-text-primary);
  text-align: center;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
}
