.blockTariffs {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 136px;
}
.title {
  width: 100%;
  font-family: var(--typography-v3-promo2-long-wide-font-family);
  font-size: var(--typography-v3-promo2-long-wide-font-size);
  line-height: var(--typography-v3-promo2-long-wide-line-height);
  font-weight: var(--typography-v3-promo2-long-wide-font-weight);
  text-align: left;
  color: var(--color-text-primary);
}
.cards {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 56px;
}
.cardsTariffs {
  display: flex;
  justify-content: center;
  gap: 32px;
}
.info {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 40px;
}

.infoText {
  text-align: center;
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-regular-text-font-family);
  font-size: var(--typography-v3-p3-regular-text-font-size);
  line-height: var(--typography-v3-p3-regular-text-line-height);
  font-weight: var(--typography-v3-p3-regular-text-font-weight);
}

@media only screen and (max-width: 1279.95px) {
  .cards {
    flex-direction: column;
  }
}

@media only screen and (max-width: 959.95px) {
  .info {
    margin-top: 56px;
  }

  .infoText {
    font-family: var(--typography-v3-p4-regular-text-font-family);
    font-size: var(--typography-v3-p4-regular-text-font-size);
    line-height: var(--typography-v3-p4-regular-text-line-height);
    font-weight: var(--typography-v3-p4-regular-text-font-weight);
  }
}

@media only screen and (max-width: 767.95px) {
  .cardsTariffs {
    flex-direction: column;
  }
  .info {
    margin-top: 32px;
  }
}
