.blockNewUser {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: center;
}
.blockInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.blockInfoHeader {
  font-family: var(--typography-v3-h4-wide-font-family);
  font-size: var(--typography-v3-h4-wide-font-size);
  line-height: var(--typography-v3-h4-wide-line-height);
  font-weight: var(--typography-v3-h4-wide-font-weight);
  color: var(--color-text-primary);
}
.blockInfoDesc {
  color: var(--color-text-primary);
  font-family: var(--typography-v3-p3-regular-comp-font-family);
  font-size: var(--typography-v3-p3-regular-comp-font-size);
  line-height: var(--typography-v3-p3-regular-comp-line-height);
  font-weight: var(--typography-v3-p3-regular-comp-font-weight);
  
}
