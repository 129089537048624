.text {
  font-family: var(--typography-v3-c1-regular-comp-font-family);
  font-size: var(--typography-v3-c1-regular-comp-font-size);
  line-height: var(--typography-v3-c1-regular-comp-line-height);
  font-weight: var(--typography-v3-c1-regular-comp-font-weight);
  width: 100%;
  height: 16px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.textError {
  color: var(--color-text-negative);
}

.textDescription {
  color: var(--color-text-secondary);
}
