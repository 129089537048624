.block {
  display: flex;
  margin-bottom: 20px;
}
.label {
  min-width: 130px;
  display: flex;
  align-items: center;
  height: 32px;
}

.labelText {
  text-align: left;
  font-family: var(--typography-v3-p4-medium-comp-font-family);
  font-size: var(--typography-v3-p4-medium-comp-font-size);
  line-height: var(--typography-v3-p4-medium-comp-line-height);
  font-weight: var(--typography-v3-p4-medium-comp-font-weight);
  color: var(--color-text-primary);
}

.fieldEdit {
  display: flex;
  gap: 8px;
}
.fieldEditButton {
  margin-left: 4px;
}

.fieldInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fieldInfoText {
  text-align: left;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}
