.link {
  text-align: left;
  color: var(--color-text-primary-link);

  &:focus-visible {
    outline: 1px solid;
    border-radius: 2px;
    outline-color: var(--color-accent-active);
  }
}
