.blockIntro {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.blockHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}
.blockAction {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin-top: 32px;
}
.blockTabs {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.blockMessage {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.blockLoader {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
