.blockTabs {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.blockAction {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin-top: 32px;
}
.blockButton {
  display: flex;
  align-self: flex-end;
}
.blockLoader {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonWrapper {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.blockEmptyData {
  max-height: 401px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
