.dialog {
  width: 100%;
  padding: 104px 164px 88px 88px;
}

.dialogHeader {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 44px;
  gap: 40px;
}

.dialogHeaderInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dialogHeaderInfoTitle {
  text-align: left;
  padding-top: 4px;
  font-family: var(--typography-v3-h2-wide-font-family);
  font-size: var(--typography-v3-h2-wide-font-size);
  line-height: var(--typography-v3-h2-wide-line-height);
  font-weight: var(--typography-v3-h2-wide-font-weight);
  color: var(--color-text-primary);
}

.dialogHeaderInfoText {
  text-align: left;
  font-family: var(--typography-v3-p3-regular-text-font-family);
  font-size: var(--typography-v3-p3-regular-text-font-size);
  line-height: var(--typography-v3-p3-regular-text-line-height);
  font-weight: var(--typography-v3-p3-regular-text-font-weight);
  color: var(--color-text-primary);
}

.dialogContent {
  display: flex;
  flex-direction: column;
  gap: 56px;
  margin-top: 56px;
}

.dialogContentForm {
  max-width: 680px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 324px);
  gap: 32px;
}

.dialogContentFormText {
  padding-top: 24px;
  font-family: var(--typography-v3-p4-regular-comp-font-family);
  font-size: var(--typography-v3-p4-regular-comp-font-size);
  line-height: var(--typography-v3-p4-regular-comp-line-height);
  font-weight: var(--typography-v3-p4-regular-comp-font-weight);
  color: var(--color-text-primary);
}

.dialogFooter {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media only screen and (max-width: 1439.95px) {
  .dialog {
    padding: 108px 124px 56px 56px;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1279.95px) {
  .dialog {
    padding: 76px 84px 40px 40px;
  }
  .dialogHeaderInfo {
    gap: 12px;
  }
  .dialogContent {
    gap: 32px;
    margin-top: 32px;
  }
  .dialogContentForm {
    row-gap: 16px;
  }
}

@media only screen and (max-width: 959.95px) {
  .dialog {
    padding: 76px 68px 40px 40px;
  }
  .dialogHeader {
    grid-template-columns: minmax(300px, 1fr) 32px;
  }
  .dialogHeaderInfo {
    gap: 16px;
  }
  .dialogHeaderInfoTitle {
    padding-top: 0px;
  }
  .dialogContent {
    gap: 40px;
    margin-top: 32px;
  }
  .dialogContentForm {
    grid-template-columns: repeat(auto-fill, 268px);
  }
}

@media only screen and (max-width: 767.95px) {
  .dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 20px;
  }
  .dialogHeader {
    width: 320px;
    display: flex;
    justify-content: center;
  }
  .dialogHeaderInfo {
    gap: 8px;
  }
  .dialogHeaderInfoTitle {
    font-family: var(--typography-v3-h3-wide-font-family);
    font-size: var(--typography-v3-h3-wide-font-size);
    line-height: var(--typography-v3-h3-wide-line-height);
    font-weight: var(--typography-v3-h3-wide-font-weight);
  }
  .dialogHeaderInfoText {
    font-family: var(--typography-v3-p4-regular-text-font-family);
    font-size: var(--typography-v3-p4-regular-text-font-size);
    line-height: var(--typography-v3-p4-regular-text-line-height);
    font-weight: var(--typography-v3-p4-regular-text-font-weight);
  }
  .dialogContent {
    gap: 24px;
    margin-top: 24px;
  }
  .dialogContentForm {
    max-width: 320px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .dialogContentFormText {
    padding-top: 0px;
    font-family: var(--typography-v3-c1-regular-comp-font-family);
    font-size: var(--typography-v3-c1-regular-comp-font-size);
    line-height: var(--typography-v3-c1-regular-comp-line-height);
    font-weight: var(--typography-v3-c1-regular-comp-font-weight);
  }
}
